import classes from "./UserSettings.module.scss";
import { useState } from "react";
import { Slider } from "primereact/slider";
import { Outlet } from "react-router-dom";

const UserSettings = (props) => {
  // const [LoyaltyPoints, setLoyaltyPoints] = useState(320);

  return (
    <div className={classes["base__customer-account"]}>
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};

export default UserSettings;
