/* eslint-disable react-hooks/exhaustive-deps */
import { FiHeart } from "react-icons/fi";
import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import classes from "./ProductCard.module.scss";

const ProductCard = ({ product, className, isFav, handleProductDeletion }) => {
  const token = localStorage.getItem("userToken");
  const [toggleAddedToFav, setToggleAddedToFav] = useState();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const addToFav = useCallback(() => {
    axios
      .post(
        `${BASE_URL}/wishlists`,
        {
          product_id: product.id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((Response) => {});
  }, []);

  const deleteFromFav = useCallback(() => {
    axios
      .delete(`${BASE_URL}/wishlists/${product.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((Response) => {
        if (handleProductDeletion) {
          handleProductDeletion(product.id);
        }
      });
  }, [handleProductDeletion, product.id, token]);

  const addToFavHandler = () => {
    if (!toggleAddedToFav) {
      addToFav();
      setToggleAddedToFav(true);
    } else if (toggleAddedToFav) {
      deleteFromFav();
      setToggleAddedToFav(false);
    }
  };

  useEffect(() => {
    if (isFav) {
      setToggleAddedToFav(true);
    } else {
      setToggleAddedToFav(false);
    }
  }, [isFav]);


  return (
    <div className={`${classes["base__product-card"]} ${className}`}>
      <Link to={`/productView/${product.slug}`} state={{ slug: product.slug }}>
        <div className={classes["card-content"]}>
          <div className={classes["card__img"]}>
            <img src={product.image.original} alt="product img" />
          </div>
          <div className={classes["card__info"]}>
            <p className={classes["product-name"]}>{product.name}</p>
            <div className={classes["info-downSide"]}>
              <p className={classes["product-brand"]}>
                {product.shop ? product.shop.name : "Brand"}
              </p>
              <div className={classes["product-price"]}>
                <p className={`${classes["price"]} `}>
                  {product.product_type === "simple" && product.sale_price && (
                    <>
                      <span className={classes["original"]}>
                        {product.price} EGP
                      </span>
                      <span className={classes["sale"]}>
                        {product.sale_price} EGP
                      </span>
                    </>
                  )}

                  {product.product_type === "simple" &&
                    !product.sale_price &&
                    `${product.price} EGP`}
                  {product.product_type === "variable" &&
                    !product.variant_min_price &&
                    `${product.min_price} EGP`}
                  {product.product_type === "variable" &&
                    product.variant_min_price && (
                      <>
                        <span className={classes["original"]}>
                          {product.max_price} EGP
                        </span>
                        <span className={classes["sale"]}>
                          {product.variant_min_price} EGP
                        </span>
                      </>
                    )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>

      {token && (
        <div className={classes["favBtn"]} onClick={addToFavHandler}>
          <FiHeart className={toggleAddedToFav ? classes["addedToFav"] : ""} />
        </div>
      )}
    </div>
  );
};

export default ProductCard;
