import classes from "./ListItem.module.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  removeFromCart,
  handleDecrease as handleDecreaseChange,
  handleIncrease as handleIncreaseChange,
} from "../../../../redux/slices/cart.slice";

const ListItem = ({ itemData}) => {
  const [count, setCount] = useState(itemData.quantity);
  const dispatch = useDispatch();

  useEffect(() => {
    setCount(itemData.quantity);
  }, [itemData]);

  const handleDecrease = () => {
    dispatch(handleDecreaseChange(itemData));
  };

  const handleIncrease = () => {
    dispatch(handleIncreaseChange(itemData));
  };
  const handleDelete = () => {
    dispatch(removeFromCart(itemData));
  };

  return (
    <div className={classes["cart__list-item"]}>
      <div className={classes["item__img"]}>
        <img src={itemData.product.image} alt="cart-item" />
      </div>
      <div className={classes["item__rightSide"]}>
        <div className={classes["item__rightSide-details"]}>
          <div className={classes["naming"]}>
            <p className={classes["item-name"]}>
              <Link
                to={`../productView/${itemData.slug}`}
                state={{ slug: itemData.slug }}
              >
                {itemData.product.name}
              </Link>
            </p>
            <p className={classes["item-brand"]}>{itemData.product.brand}</p>
          </div>
          <div className={classes["attr"]}>
            <p className={classes["item-price"]}>
              <p>Price : </p>
              <p>
                {itemData.type === "simple" &&
                  itemData.product.simpleSalePrice && (
                    <>
                      <span className={classes["original"]}>
                        {itemData.product.simplePrice} EGP
                      </span>
                      <span className={classes["sale"]}>
                        {itemData.product.simpleSalePrice} EGP
                      </span>
                    </>
                  )}
                {itemData.type === "simple" &&
                  !itemData.product.simpleSalePrice &&
                  `${itemData.product.simplePrice} EGP`}
                {itemData.type === "variable" &&
                  !itemData.salePrice &&
                  itemData.price}
                {itemData.type === "variable" && itemData.salePrice && (
                  <>
                    <span className={classes["original"]}>
                      {itemData.price} EGP
                    </span>
                    <span className={classes["sale"]}>
                      {itemData.salePrice} EGP
                    </span>
                  </>
                )}
              </p>
            </p>
            {itemData.type === "variable" && (
              <p className={classes["size"]}>
                Size : <span>{itemData.size}</span>
              </p>
            )}
          </div>
        </div>
        <div className={classes["item__rightSide-actions"]}>
          <div className={classes["item-counter"]}>
            <i className="pi pi-minus" onClick={handleDecrease}></i>
            <p>{count}</p>
            <i className="pi pi-plus" onClick={handleIncrease}></i>
          </div>
          <div className={classes["item-actions"]}>
            {/* <FiHeart />
            <FiShare /> */}
            <FiTrash2 onClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
