import { Dropdown } from "primereact/dropdown";
import classes from "./Dropdown.module.scss";

const DropdownInput = (props) => {
  return (
    <div className={classes["dropdownField"]}>
      {props.label && (
        <label htmlFor={props.id}>
          {props.label}
          <span>*</span>
        </label>
      )}
      <Dropdown
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        options={props.options}
        optionLabel="name"
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default DropdownInput;
