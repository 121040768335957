import { Outlet } from "react-router-dom";
import classes from "./Cart.module.scss";

const Cart = () => {
  return (
    <div className={classes["base__cart"]}>
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};

export default Cart;
