import classes from "./Wishlist.module.scss";
import { ProductCard } from "../../../../../components";

import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const Wishlist = () => {
  const token = localStorage.getItem("userToken");
  const [favProducts, setFavProducts] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchFavProducts = useCallback(() => {
    axios
      .get(`${BASE_URL}/my-wishlists`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedFavProducts = response.data.data;
        setFavProducts(fetchedFavProducts);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchFavProducts();
  }, [fetchFavProducts]);

  const handleProductDeletion = (productId) => {
    setFavProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== productId)
    );
  };

  return (
    <div className={classes["wishlist-content"]}>
      <h1>Wishlist</h1>
      <div className={`${classes["products-list"]} row`}>
        {favProducts.length ? (
          favProducts.map((product) => {
            return (
              <div key={product.id} className="col-lg-4 col-md-4">
                <ProductCard
                  isFav={product.in_wishlist}
                  product={product}
                  handleProductDeletion={handleProductDeletion}
                />
              </div>
            );
          })
        ) : (
          <div className={`${classes["emptyList"]} col-md-12`}>
            <h1>No Products added yet</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Wishlist;
