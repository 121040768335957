import classes from "./SubImageView.module.scss";
import { useNavigate } from "react-router-dom";

const SubImageView = (props) => {
  const navigate = useNavigate();

  const navToProducts = () => {
    let slug, name, id;
    if (props.brand === "dual") {
      slug = "dual";
      name = "Dual";
      id = 4;
    } else if (props.brand === "theá") {
      slug = "theá";
      name = "Theá";
      id = 15;
    } else if (props.brand === "riddle") {
      slug = "riddle";
      name = "Riddle";
      id = 24;
    } else {
      return;
    }
    navigate(`../products/${slug}`, {
      state: {
        typeName: name,
        filterType: "brand",
        shopId: id,
      },
    });
  };

  return (
    <div className={`${classes["base__home-SubImage"]} ${props.className}`}>
      <div className={classes["subImageContainer"]}>
        <img onClick={navToProducts} src={props.img} alt="sponser product" />
      </div>
      <p>{props.description}</p>
    </div>
  );
};

export default SubImageView;
