import classes from "./TermsAndConditions.module.scss";
import { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes["terms-container"]}>
      <h1>Terms and Conditions</h1>

      <ol>
        <li>
          <b>Introduction</b> <br /> Welcome to our e-commerce website. These
          terms and conditions of use (the "Terms") apply to your use of our
          website and any purchases made through our website. By using our
          website, you agree to be bound by these Terms. If you do not agree to
          these Terms, do not use our website.
        </li>
        <li>
          <b>Changes</b> <br /> to the Terms We may modify these Terms at any
          time, without prior notice. Your continued use of our website after
          any changes to these Terms constitutes your acceptance of the modified
          Terms
        </li>
        <li>
          <b>Intellectual Property</b>
          <br /> All content on our website, including but not limited to text,
          graphics, logos, images, and software, is the property of our company
          or our licensors and is protected by copyright and other intellectual
          property laws. You may not use any content from our website without
          our express written consent
        </li>
        <li>
          <b>Use of Our Website</b>
          <br />
          You may use our website only for lawful purposes and in accordance
          with these Terms. You may not use our website in any way that violates
          any applicable federal, govermental, local, or international law or
          regulation
        </li>
        <li>
          <b>Product Descriptions</b>
          <br />
          We make every effort to ensure that the products listed on our website
          are accurately described and priced. However, we do not warrant that
          the product descriptions or prices are accurate, complete, or
          error-free. We reserve the right to correct any errors or omissions,
          and to update or change pricing information at any time without prior
          notice
        </li>
        <li>
          <b>Payment and Shipping</b>
          <br />
          We accept payment by credit card or other payment methods as indicated
          on our website. We will ship your order as soon as possible after
          payment is received. We are not responsible for any delays or damage
          caused during shipping
        </li>
        <li>
          <b>Returns and Refunds</b>
          <br />
          We accept returns and offer refunds in accordance with our return
          policy, which is posted on our website. We reserve the right to refuse
          any returns or refunds that do not comply with our return policy
        </li>
        <li>
          <b>Limitation of Liability</b>
          <br />
          In no event will we be liable for any direct, indirect, incidental,
          special, or consequential damages arising out of or in connection with
          your use of our website or any products purchased through our website
        </li>
        <li>
          <b>Indemnification</b>
          <br />
          You agree to indemnify and hold us harmless from any claims or damages
          arising out of your use of our website or any products purchased
          through our website
        </li>
        <li>
          <b>Governing Law</b>
          <br />
          These Terms shall be governed by and construed in accordance with the
          laws of the jurisdiction in which our company is located, without
          regard to its conflict of law provisions
        </li>
        <li>
          <b>Dispute Resolution</b>
          <br />
          Any dispute arising out of or in connection with these Terms shall be
          resolved through arbitration in accordance with the rules of the
          American Arbitration Association
        </li>
        <li>
          <b>Entire Agreement</b>
          <br />
          These Terms constitute the entire agreement between you and our
          company regarding your use of our website and any products purchased
          through our website
        </li>
        <li>
          <b>Contact Information</b>
          <br />
          If you have any questions or comments about these Terms, please
          contact us at <a href="mailto:contact@base-egypt.com">Base</a>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
