import classes from "./Brands.module.scss";
import { Search } from "../../components";
import { FiHeart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";

const Brands = () => {
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [favBrands, setFavBrands] = useState([]);
  const [brandsLinks, setBrandsLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchBrands = useCallback(() => {
    axios
      .get(`${BASE_URL}/shops`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoading(false);
        const fetchedBrands = response.data.data.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            slug: brand.slug,
            isFav: brand.in_wishlist,
          };
        });
        fetchedBrands.sort((a, b) => a.name.localeCompare(b.name));

        setBrands(fetchedBrands);
        setBrandsLinks(response.data.links.slice(1, -1));
      });
  }, []);

  const fetchFavBrands = useCallback(() => {
    axios
      .get(`${BASE_URL}/my-shop-wishlists`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedBrands = response.data.data.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            slug: brand.slug,
            isFav: brand.in_wishlist,
          };
        });
        setFavBrands(fetchedBrands);
      });
  }, []);

  useEffect(() => {
    fetchBrands();
    fetchFavBrands();
  }, []);

  const addToFav = useCallback((id) => {
    axios.post(
      `${BASE_URL}/shop-wishlists`,
      {
        shop_id: id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }, []);

  const removeFromFav = useCallback((id) => {
    axios.delete(`${BASE_URL}/shop-wishlists/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    favBrands.filter((brand) => brand.id !== id);
  }, []);

  const toggleAddBrandToFav = (brand) => {
    if (brand.isFav) {
      removeFromFav(brand.id);
      setFavBrands((prevFavBrands) =>
        prevFavBrands.filter((favBrand) => favBrand.id !== brand.id)
      );
      const index = brands.findIndex((item) => item.id === brand.id);
      const itemToUpdate = { ...brands[index], isFav: false };
      const updatedBrands = [...brands];
      updatedBrands[index] = itemToUpdate;
      setBrands(updatedBrands);
    } else if (!brand.isFav) {
      addToFav(brand.id);
      setFavBrands([...favBrands, { ...brand, isFav: true }]);
      const index = brands.findIndex((item) => item.id === brand.id);
      const itemToUpdate = { ...brands[index], isFav: true };
      const updatedBrands = [...brands];
      updatedBrands[index] = itemToUpdate;
      setBrands(updatedBrands);
    }
  };

  const navToBrand = (brand) => {
    navigate(`../products/${brand.slug}`, {
      state: { typeName: brand.name, filterType: "brand", shopId: brand.id },
    });
  };

  const handlePageClick = (e) => {
    const link = brandsLinks[e.selected].url;
    axios
      .get(link, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedBrands = response.data.data.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            slug: brand.slug,
            isFav: brand.in_wishlist,
          };
        });
        setBrands(fetchedBrands);
      })
      .then(() => {
        window.scrollTo(0, 0);
      });
  };

  return (
    <div className={classes["base__brands"]}>
      {loading && <Spinner />}
      {!loading && (
        <div className="container">
          <div className={`${classes["base__brands-content"]} row`}>
            <div className={`${classes["brands-section"]} col-lg-9`}>
              <div className={classes["favorite-brands"]}>
                <h2>Your Favorite Brands</h2>
                {favBrands.length > 0 ? (
                  <div className={classes["favBrands__list"]}>
                    {favBrands.map((brand, index) => (
                      <div
                        key={index}
                        className={classes["favBrands__list-item"]}
                      >
                        <FiHeart
                          onClick={() => {
                            toggleAddBrandToFav(brand);
                          }}
                        />
                        <p onClick={() => navToBrand(brand)}>{brand.name}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <FiHeart className={classes["emptyList-icon"]} />
                    <p>
                      Like your first brand to get notifications of new drops and
                      sales
                    </p>
                  </>
                )}
              </div>
              <h2>A - Z Brands</h2>
              <div className={classes["brands__list"]}>
                {brands.map((brand, index) => (
                  <div key={index} className={classes["brands__list-item"]}>
                    <p onClick={() => navToBrand(brand)}>{brand.name}</p>
                    <FiHeart
                      className={brand.isFav ? classes["addedToFav"] : ""}
                      onClick={() => toggleAddBrandToFav(brand)}
                    />
                  </div>
                ))}
              </div>
              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={brandsLinks.length}
                renderOnZeroPageCount={null}
                className={classes["paginationContainer"]}
                nextLabel="Next"
                previousLabel="Prev"
                activeClassName={classes["activePage"]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Brands;
