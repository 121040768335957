import classes from "./RefineMenu.module.scss";
import { Navbar } from "../../components";
import { useState } from "react";
import { RadioButton } from "primereact/radiobutton";

const RefineMenu = (props) => {
  const filters = [
    { TypeName: "All", slug: "all" },
    { TypeName: "Unisex", slug: "unisex" },
    { TypeName: "Women", slug: "women" },
    { TypeName: "Men", slug: "men" },
  ];

  const refines = [
    { name: "Shop All", key: "all" },
    { name: "Price: Low to High", key: "lth" },
    { name: "Price: High to Low", key: "htl" },
  ];

  const [selectedRefine, setSelectedRefine] = useState(refines[0]);

  const changeRefineFilter = (e) => {
    setSelectedRefine(e.value);
    if (e.value.key === "all") {
      props.setOriginal();
    }
    if (e.value.key === "lth") {
      props.sortLowToHigh();
    } else if (e.value.key === "htl") {
      props.sortHighToLow();
    }
  };

  return (
    <div className={props.className}>
      <h4 className={classes["RefineMenu__header"]}>Refine</h4>
      <div className={classes["Refine__nav"]}>
        {/* filter submenu */}
        <Navbar
          className={classes["Refine__subMenu"]}
          navOptions={filters}
          type="subMenuFilter"
        />

        {/* radio options */}
        <div className={classes["radio-options"]}>
          {refines.map((refine) => {
            return (
              <div key={refine.key} className={classes["radio-item"]}>
                <RadioButton
                  inputId={refine.key}
                  name="refine"
                  value={refine}
                  onChange={changeRefineFilter}
                  checked={selectedRefine.key === refine.key}
                />
                <label htmlFor={refine.key} className="ml-2">
                  {refine.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RefineMenu;
