import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import images from "../../constants/images";
import classes from "./Footer.module.scss";
import { GrSnapchat, GrYoutube } from "react-icons/gr";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";

const Footer = () => {
  const [showElement, setShowElement] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 768) {
      setShowElement(true);
    } else {
      setShowElement(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className={classes["base__footer"]}>
      <div className={`${classes["footer__content"]} container`}>
        <div className={classes["footer__content-left"]}>
          <div className={classes["links-container"]}>
            <h2>About BASE</h2>
            <div className={classes["footer__links"]}>
              <div className={classes['linksBlock']}>
                <p>
                  <Link to="user-settings/settings-list/about">About Us</Link>
                </p>
                <p>
                  <Link to="user-settings/settings-list/contact-us">
                    Contact Us
                  </Link>
                </p>
              </div>
              <div className={classes['linksBlock']}>
                <p>
                  <Link
                    target="_blank"
                    to="https://docs.google.com/forms/d/1qiPkmMTsvtOVBLhQY17P2CgiUxrLs1P4t5UyGWwZJE4/viewform?ts=646a53f8&edit_requested=true"
                  >
                    Join as a Seller
                  </Link>
                </p>
                <p>
                  <Link to="user-settings/settings-list/privacy-policy">
                    Privacy Policy
                  </Link>
                </p>
              </div>
              <div className={classes['linksBlock']}>
                <p>
                  <Link to="user-settings/settings-list/faqs-and-guides">
                    Refund and Exchange policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes["footer__content-right"]}>
          <div>
            <h2>Follow Us</h2>
            <div className={classes["social-icons"]}>
              {/* <GrSnapchat /> */}
              <Link to="https://www.instagram.com/baseegypt/ ">
                <BsInstagram />
              </Link>
              <Link to="https://www.facebook.com/baseegyptshop ">
                <FaFacebookF />
              </Link>
              <Link to="https://www.tiktok.com/@baseegypt ">
                <FaTiktok />
              </Link>
              {/* <GrYoutube /> */}
              {/* <BsTwitter /> */}
            </div>
          </div>
          <div className={classes["bottomSide-copyRights"]}>
            <div className={classes["copyrights"]}>
              <p>
                &rsquo;BASE&rsquo; and the &rsquo;BASE&rsquo; logo are trade
                marks of Base and are registered.
              </p>
              <p>&#169; Copyright 2023 BASE. All rights reserved.</p>
            </div>
            <div className={classes["powered-by"]}>
              <p>Powered By</p>
              <Link to="https://www.instagram.com/zsolutions_eg/">
                <img src={images.zSolutions} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
