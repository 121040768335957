import classes from "./BaseMessage.module.scss";
import { useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";

const BaseMessage = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const orderNumber = location.state.orderNumber;

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, []);

  return (
    <div className={classes["base__message-container"]}>
      {params.messageType === "confirmOrder" && (
        <>
          <p className={classes["message-head"]}>Order Placed Successfully</p>
          <p className={classes["message-content"]}>
            Reference Number #{orderNumber}
          </p>
          <p className={classes["message-content"]}>
            Confirmation sent to Email
          </p>
          <p className={classes["message-box"]}>
            Thank You for shopping with <span>BASE</span>
          </p>
        </>
      )}

      <Link to="/">
        <p>Back to Home</p>
      </Link>
    </div>
  );
};

export default BaseMessage;
