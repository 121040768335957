import classes from "./About.module.scss";
import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes["about-container"]}>
      <h1>About Us</h1>
      <p>BASE stands for Buyers and Sellers E-Commerce. We do fashion</p>
      <p>
        Base is an E-commerce marketplace platform designed for all local
        brands. Our goal is to facilitate the shopping experience for customers
        and provide the vendors with the support they need to boost their sales,
        visibility and help them reach their full potential.
      </p>

      <h1>VISION</h1>
      <p>
        To become the leading fashion marketplace platform in the Middle East.
      </p>

      <h1>MISSION</h1>
      <p>
        At BASE, our mission is to facilitate the shopping experience and
        fulfilling the customer's needs by supporting local brands through a
        smart, convenient, and automated E-commerce platform
      </p>
    </div>
  );
};

export default About;
