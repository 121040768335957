import classes from "./PrivacyPolicy.module.scss";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes["policy-container"]}>
      <h1>Privacy Policy for BASE</h1>
      <ol>
        <li>
          <b>Introduction</b>
          <br />
          We are committed to protecting your privacy and ensuring the security
          of your personal information. This privacy policy outlines how we
          collect, use, and protect your personal information when you use our
          e-commerce website
        </li>
        <li>
          <b>Information We Collect</b>
          <br />
          We collect personal information from you when you use our website or
          make a purchase. This may include your name, email address, shipping
          address, billing address, phone number, and payment information
        </li>
        <li>
          <b>Use of Information</b>
          <br />
          We use your personal information to process your orders, communicate
          with you about your orders, and provide customer support. We may also
          use your personal information to send you marketing communications,
          such as newsletters and promotions, if you have opted in to receive
          them
        </li>
        <li>
          <b>Sharing of Information</b>
          <br />
          We do not sell or share your personal information with third parties
          except as necessary to fulfill your orders or as required by law. We
          may share your personal information with our service providers, such
          as payment processors and shipping companies, to facilitate the
          delivery of your orders
        </li>
        <li>
          <b>Protection of Information</b>
          <br />
          We take reasonable measures to protect your personal information from
          unauthorized access, disclosure, or loss. However, no security
          measures are 100% effective, and we cannot guarantee the security of
          your personal information
        </li>
        <li>
          <b>Data Retention</b>
          <br />
          We retain your personal information for as long as necessary to
          fulfill your orders and as required by law. We may also retain your
          personal information for marketing purposes, unless you opt out of
          receiving marketing communications
        </li>
        <li>
          <b>Cookies</b>
          <br />
          We use cookies and similar technologies to track your preferences and
          personalize your experience on our website. You can disable cookies in
          your browser settings, but this may affect your ability to use our
          website
        </li>
        <li>
          <b>Your Rights</b>
          <br />
          You have the right to access, correct, or delete your personal
          information at any time. You may also opt out of receiving marketing
          communications at any time
        </li>
        <li>
          <b>Children's Privacy</b>
          <br />
          Our website is not intended for use by children under the age of 13.
          We do not knowingly collect personal information from children under
          the age of 13
        </li>
        <li>
          <b>Changes to this Policy</b>
          <br />
          We may modify this policy at any time, without prior notice. Your
          continued use of our website after any changes to this policy
          constitutes your acceptance of the modified policy
        </li>
        <li>
          <b>Contact Information</b>
          <br />
          If you have any questions or comments about this policy, please
          contact us at <a href="mailto:contact@base-egypt.com">Base</a>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
