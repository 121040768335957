import { Outlet, Link } from "react-router-dom";
import { Button } from "../../../components";
import { useState } from "react";
import classes from "./SettingsList.module.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SettingsList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isUserLogged")
  );
  const [toggleUserSettings, setToggleUserSettings] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    axios.post(`${BASE_URL}/logout`);
    localStorage.clear();
    localStorage.setItem("totalQuantity", 0);
    window.dispatchEvent(new Event("storage"));
    navigate("/");
  };

  return (
    <div className={classes["settingsList-container"]}>
      <div className="row justify-content-between">
        <div className="col-md-3">
          <div className={classes["account__options"]}>
            {isLoggedIn && (
              <>
                <h4>My Account</h4>
                <div className={classes["account__options-list"]}>
                  <Link to={"orders-and-returns"}>
                    <p>
                      Orders & Returns <i className="pi pi-angle-right"></i>
                    </p>
                  </Link>
                  {/* <Link to={"wallet"}>
                <p>
                  Wallet <i className="pi pi-angle-right"></i>
                </p>
              </Link> */}
                  {/* <Link to={"details-and-password"}>
                    <p>
                      Details & Password <i className="pi pi-angle-right"></i>
                    </p>
                  </Link> */}
                  <Link to={"address-book"}>
                    <p>
                      Address Book <i className="pi pi-angle-right"></i>
                    </p>
                  </Link>
                  {/* <Link to={"refer-a-friend"}>
                <p>
                  Refer a Friend <i className="pi pi-angle-right"></i>
                </p>
              </Link> */}
                  <Link to={"wishlist"}>
                    <p>
                      Wishlist <i className="pi pi-angle-right"></i>
                    </p>
                  </Link>
                </div>
              </>
            )}

            <h4>Support</h4>
            <div className={classes["account__options-list"]}>
              <Link to={"about"}>
                <p>
                  About Us <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"terms-and-conditions"}>
                <p>
                  Terms & Conditions <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"privacy-policy"}>
                <p>
                  Privacy Policy <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"faqs-and-guides"}>
                <p>
                  Refund and Exchange policy{" "}
                  <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"contact-us"}>
                <p>
                  Contact Us <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link
                target="_blank"
                to="https://docs.google.com/forms/d/1qiPkmMTsvtOVBLhQY17P2CgiUxrLs1P4t5UyGWwZJE4/viewform?ts=646a53f8&edit_requested=true"
              >
                <p>
                  Join as a Seller <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              {isLoggedIn && (
                <p onClick={handleLogout}>
                  Sign Out <i className="pi pi-angle-right"></i>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12">
          <Outlet />
        </div>
      </div>

      <Button
        icon="pi pi-list"
        className={`${classes["settings__menu-button"]} btn-primary`}
        onClick={() => {
          setToggleUserSettings(!toggleUserSettings);
        }}
      />

      {toggleUserSettings && (
        <div className={classes["base__settings-menu"]}>
          <div className={classes["menu__account-options"]}>
            {isLoggedIn && (
              <>
                <h4>My Account</h4>
                <div className={classes["account__options-list"]}>
                  <Link to={"orders-and-returns"}>
                    <p>
                      Orders & Returns <i className="pi pi-angle-right"></i>
                    </p>
                  </Link>
                  {/* <Link to={"wallet"}>
                <p>
                  Wallet <i className="pi pi-angle-right"></i>
                </p>
              </Link> */}
                  <Link to={"details-and-password"}>
                    <p>
                      Details & Password <i className="pi pi-angle-right"></i>
                    </p>
                  </Link>
                  <Link to={"address-book"}>
                    <p>
                      Address Book <i className="pi pi-angle-right"></i>
                    </p>
                  </Link>
                  {/* <Link to={"refer-a-friend"}>
                <p>
                  Refer a Friend <i className="pi pi-angle-right"></i>
                </p>
              </Link> */}
                  <Link to={"wishlist"}>
                    <p>
                      Wishlist <i className="pi pi-angle-right"></i>
                    </p>
                  </Link>
                </div>
              </>
            )}

            <h4>Support</h4>
            <div className={classes["account__options-list"]}>
              <Link to={"about"}>
                <p>
                  About Us <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"terms-and-conditions"}>
                <p>
                  Terms & Conditions <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"privacy-policy"}>
                <p>
                  Privacy Policy <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"faqs-and-guides"}>
                <p>
                  Refund and Exchange policy{" "}
                  <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link to={"contact-us"}>
                <p>
                  Contact Us <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <Link
                target="_blank"
                to="https://docs.google.com/forms/d/1qiPkmMTsvtOVBLhQY17P2CgiUxrLs1P4t5UyGWwZJE4/viewform?ts=646a53f8&edit_requested=true"
              >
                <p>
                  Join as a Seller <i className="pi pi-angle-right"></i>
                </p>
              </Link>
              <p onClick={handleLogout}>
                Sign Out <i className="pi pi-angle-right"></i>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsList;
