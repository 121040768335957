import { useCallback, useEffect, useState } from "react";
import classes from "./Address.module.scss";
import AddressListItem from "./AddressListItem/AddressListItem";
import { BsChevronRight } from "react-icons/bs";
import { Button } from "../../../../../components";
import axios from "axios";
import {
  Link,
  useOutletContext,
  useNavigate,
  useLocation,
} from "react-router-dom";

const Address = () => {
  const token = localStorage.getItem("userToken");
  const [setActiveIndex] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  let isUpdated = location.state.addressesUpdated;

  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(
    JSON.parse(localStorage.getItem("selectedAddress"))
  );

  const fetchAddressList = useCallback(() => {
    axios
      .get(`${BASE_URL}/my-addresses`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedAddressList = response.data.data.map((address) => {
          return {
            addressId: address.id,
            city: address.address.city,
            country: address.address.country,
            landmark: address.address.landmark,
            phone: address.address.phone_number,
            street1: address.address.street_1,
            street2: address.address.street_2,
            customerId: address.customer_id,
          };
        });

        setAddressList([...fetchedAddressList]);

        if (fetchedAddressList.length > 0) {
          if (selectedAddress !== null) {
            setSelectedAddress(
              JSON.parse(localStorage.getItem("selectedAddress"))
            );
          } else {
            setSelectedAddress(fetchedAddressList[0].addressId);
            localStorage.setItem(
              "selectedAddress",
              JSON.stringify(fetchedAddressList[0])
            );
          }
        } else {
          setSelectedAddress(null);
          localStorage.setItem("selectedAddress", null);
        }
      });
  }, []);

  const selectAddress = (id, addressInfo) => {
    setSelectedAddress(addressInfo);
    localStorage.setItem("selectedAddress", JSON.stringify(addressInfo));
  };

  useEffect(() => {
    setActiveIndex(0);
    fetchAddressList();
    isUpdated = false;
  }, [isUpdated]);

  const handleButtonClick = () => {
    setActiveIndex(1);
    navigate("../payment");
  };

  return (
    <div className={classes["address-container"]}>
      <h2>Choose Address</h2>
      <div className={classes["address-list"]}>
        {addressList.map((address, index) => (
          <AddressListItem
            selectedAddress={selectedAddress}
            changeSelectedAddressHandler={selectAddress}
            addressInfo={address}
            key={index}
          />
        ))}
        <Link to={"add-new-address"} state={{ formType: "add" }}>
          <h5 className={classes["newAddressLink"]}>
            <span>Add New Address</span>
            <span>
              <BsChevronRight />
            </span>
          </h5>
        </Link>
      </div>
      <div className={classes["btn-holder"]}>
        {addressList.length > 0 && (
          <Button
            onClick={handleButtonClick}
            className="btn-primary"
            label="Proceed"
          />
        )}
      </div>
    </div>
  );
};

export default Address;
