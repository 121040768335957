import { Dialog } from "primereact/dialog";
import { useState } from "react";
import classes from "./UserDialog.module.scss";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";

import {
  Button,
  TextInput,
  PasswordInput,
  CalendarInput,
} from "../../../../components";

const UserDialog = (props) => {
  // login states
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  // registration states
  const [registrationFirstName, setRegistrationFirstName] = useState("");
  const [registrationLastName, setRegistrationLastName] = useState("");
  const [registrationEmail, setRegistrationEmail] = useState("");
  const [registrationPass, setRegistrationPass] = useState("");
  const [registrationRepeatPass, setRegistrationRepeatPass] = useState("");
  const [registrationReferCode, setRegistrationReferCode] = useState("");
  const [regEmailError, setRegEmailError] = useState("");
  const [regPassError, setRegPassError] = useState("");
  const [regRepeatPassError, setRegRepeatPassError] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // get logged user id

  // login handlers
  const loginEmailChangeHandler = (e) => {
    setLoginEmail(e.target.value);
  };

  const loginPasswordChangeHandler = (e) => {
    setLoginPassword(e.target.value);
  };

  const handleLoginFormSubmit = (e) => {
    // console.log("firing login");
    e.preventDefault();

    axios
      .post(`${BASE_URL}/token`, {
        email: loginEmail,
        password: loginPassword,
      })
      .then((Response) => {
        if (Response.data.token) {
          props.changeStatus(true);
          localStorage.setItem("userToken", `${Response.data.token}`);
          localStorage.setItem("isUserLogged", true);
          window.dispatchEvent(new Event("storage"));
          setLoginError("");
          props.setIsDialogVisible(false);
          localStorage.setItem("totalQuantity", 0);
        } else if (Response.data.token === null) {
          setLoginError("Invalid Email or Password");
        }
      });
  };

  // registration handlers

  const regFirstNameChangeHandler = (e) => {
    setRegistrationFirstName(e.target.value);
  };

  const regLastNameChangeHandler = (e) => {
    setRegistrationLastName(e.target.value);
  };

  const regEmailChangeHandler = (e) => {
    setRegistrationEmail(e.target.value);
  };

  const regPassChangeHandler = (e) => {
    setRegistrationPass(e.target.value);
  };

  const regRepeatPassChangeHandler = (e) => {
    setRegistrationRepeatPass(e.target.value);
  };

  const regReferCodeChangeHandler = (e) => {
    setRegistrationReferCode(e.target.value);
  };

  const validateRegEmail = () => {
    const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    if (emailPattern.test(registrationEmail)) {
      setRegEmailError("");
      return true;
    } else {
      setRegEmailError("Please enter a valid email address.");
      return false;
    }
  };

  const validateRegPassword = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>.]{8,}$/;
    if (passwordPattern.test(registrationPass)) {
      setRegPassError("");

      if (registrationPass !== registrationRepeatPass) {
        setRegRepeatPassError("passwords don't match");
        return false;
      }
      setRegRepeatPassError("");
      return true;
    } else {
      setRegPassError(
        "Please use a mix of uppercase and lowercase letters, numbers and special characters. your password must be at least 8 characters long"
      );
      return false;
    }
  };

  const handleRegistrationFormSubmit = (e) => {
    e.preventDefault();

    if (validateRegEmail() && validateRegPassword()) {
      axios
        .post(`${BASE_URL}/register`, {
          name: `${registrationFirstName} ${registrationLastName}`,
          email: registrationEmail,
          password: registrationPass,
        })
        .then((Response) => {
          console.log(Response.data);
          if (Response.data.token) {
            props.changeStatus(true);
            localStorage.setItem("userToken", `${Response.data.token}`);
            localStorage.setItem("isUserLogged", true);
            props.setIsDialogVisible(false);
            props.showConfirmation();
            localStorage.setItem("totalQuantity", 0);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("req failed");
          setRegEmailError(err.response.data.email);
        });
    }
  };

  let dialogContent = () => {
    if (props.type === "login") {
      return (
        <form onSubmit={handleLoginFormSubmit}>
          <div className={classes["form-box"]}>
            <TextInput
              value={loginEmail}
              onChange={loginEmailChangeHandler}
              id="email"
              label="Email"
            />
            <PasswordInput
              value={loginPassword}
              onChange={loginPasswordChangeHandler}
              id="password"
              label="Password"
            />
            <small className={classes["login-error"]}>{loginError}</small>
          </div>
          <Button type="submit" className="btn-primary w-100" label="Sign In" />
        </form>
      );
    }
    if (props.type === "signup") {
      return (
        <form onSubmit={handleRegistrationFormSubmit}>
          <div className={classes["form-box"]}>
            <TextInput
              value={registrationFirstName}
              onChange={regFirstNameChangeHandler}
              id="firstName"
              label="First Name"
            />
            <TextInput
              value={registrationLastName}
              onChange={regLastNameChangeHandler}
              id="lastName"
              label="Last Name"
            />
            <TextInput
              value={registrationEmail}
              onChange={regEmailChangeHandler}
              id="email"
              label="Email"
              hint={regEmailError}
            />
            <PasswordInput
              value={registrationPass}
              onChange={regPassChangeHandler}
              id="password"
              label="Password"
              hint={regPassError}
            />
            <PasswordInput
              value={registrationRepeatPass}
              onChange={regRepeatPassChangeHandler}
              id="repeatpassword"
              label="Repeat Password"
              hint={regRepeatPassError}
            />
            {/* <TextInput
              value={registrationReferCode}
              onChange={regReferCodeChangeHandler}
              id="referralCode"
              label="Referral Code"
            /> */}
          </div>
          <div className={classes["checkbox-holder"]}>
            <Checkbox
              inputId="terms"
              name="terms"
              value="terms"
              onChange={() => {
                setAgreeToTerms(!agreeToTerms);
              }}
              checked={agreeToTerms}
            />
            <label htmlFor="terms" className="ml-2">
              Agree to{" "}
              <Link
                onClick={() => props.setIsDialogVisible(false)}
                to={"../user-settings/settings-list/terms-and-conditions"}
              >
                Terms & Conditions
              </Link>
            </label>
          </div>

          <Button
            disabled={!agreeToTerms}
            type="submit"
            className="btn-primary w-100"
            label="Sign Up"
          />
        </form>
      );
    }
  };

  const toggleActiveButton = (index, buttonType) => {
    props.setActiveButton(index);

    if (buttonType === "Sign In") {
      props.changeType("login");
      props.setActiveButton(0);
    } else if (buttonType === "Join Us") {
      props.changeType("signup");
      props.setActiveButton(1);
    }
  };

  const renderButtons = () => {
    const buttons = ["Sign In", "Join Us"];

    return buttons.map((button, index) => (
      <button
        key={index}
        className={`${classes["dialog-btn"]} ${
          props.activeButton === index ? classes.active : ""
        }`}
        onClick={() => toggleActiveButton(index, button)}
      >
        {button}
      </button>
    ));
  };

  return (
    <Dialog
      visible={props.isVisible}
      style={{ width: "30vw" }}
      draggable={false}
      breakpoints={{
        "1199px": "40vw",
        "960px": "45vw",
        "641px": "70vw",
        "575px": "90vw",
      }}
      onHide={() => props.setIsVisible(false)}
      header={
        <div className={classes.buttonContainer}>
          {renderButtons()}
          <div
            className={classes.slider}
            style={{
              transform: `translateX(${props.activeButton * 100}%)`,
            }}
          ></div>
        </div>
      }
    >
      {dialogContent()}
    </Dialog>
  );
};

export default UserDialog;
