import SubImageView from "./components/SubImageView/SubImageView";
import CategoryView from "./components/CategoryView/CategoryView";
import { Button } from "../../components";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { images } from "../../constants";
import classes from "./Home.module.scss";

const Home = () => {
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [newInProducts, setNewInProducts] = useState([]);
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const [favBrands, setFavBrands] = useState([]);

  const fetchNewInProducts = useCallback(() => {
    axios
      .get(
        `${BASE_URL}/products?search=tags.slug:new_in&limit=6`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const newIn = response.data.data;
        setNewInProducts(newIn);
      });
  }, []);

  const fetchBestSellerProducts = useCallback(() => {
    axios
      .get(
        `${BASE_URL}/products?search=tags.slug:best_seller&limit=6`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const bestSellers = response.data.data;
        setBestSellerProducts(bestSellers);
      });
  }, []);

  const fetchFavBrandsProducts = useCallback(() => {
    axios
      .get(
        `${BASE_URL}/products?search=tags.slug:favorite_brand&limit=7`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const favBrands = response.data.data;
        setFavBrands(favBrands);
      });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchNewInProducts();
    fetchBestSellerProducts();
    fetchFavBrandsProducts();
  }, [fetchNewInProducts, fetchBestSellerProducts]);

  const navToShopAll = () => {
    navigate(`../products/lgnd`, {
      state: {
        typeName: "LGND",
        filterType: "brand",
        shopId: 8,
      },
    });
  };

  return (
    <>
      <div className={classes["base__home"]}>
        <div className="container">
          {/* home page main section displaying sponser message and product image of selection */}

          <div className={classes["home__hero-section"]}>
            <div className={classes["home__base-message"]}>
              <h3>LGND</h3>
              <p>
                Unleash the power of your story with LGND, a brand established
                in 2020 that embodies timeless elegance, unparalleled quality,
                and a legacy of excellence.
              </p>
              <div className={classes["message-btn"]}>
                <Button
                  className={`${classes["btn-customWidth"]} btn-primary`}
                  label="Shop Now"
                  onClick={navToShopAll}
                />
              </div>
            </div>
            <div className={classes["home__hero-img"]}>
              <img
                onClick={() => {
                  navigate(`../products/lgnd`, {
                    state: {
                      typeName: "LGND",
                      filterType: "brand",
                      shopId: 8,
                    },
                  });
                }}
                src={images.feature1}
                alt=""
              />
            </div>
          </div>

          {/* home page body gallery containnig sponser images and subCategories with card sliders */}

          <div className={`${classes["home__body-gallery"]} row`}>
            {/* left side section of home page displaying different mini categories sections with product slider */}

            <div className={` ${classes["subCategoriesView"]} col-lg-6`}>
              <CategoryView
                sliderRows={1}
                products={bestSellerProducts}
                className={classes["custom__img-width"]}
                header="Best Sellers"
                description="Browse our Best Sellers collection & find your fashion statement with our most popular styles"
              />
              <CategoryView
                sliderRows={1}
                products={newInProducts}
                className={classes["custom__img-width"]}
                header="New In"
                description="Explore our New In collection & upgrade your wardrobe with the latest arrivals"
              />
              <CategoryView
                sliderRows={1}
                products={favBrands}
                className={classes["custom__img-width"]}
                header="Our Favorite Brands"
                description="Shop the best of the best & check out Our Favorite Brands"
              />
              {/* <CategoryView
                productCount={23}
                sliderRows={2}
                className={classes["custom__img-width"]}
                header="Our Favorite Brands"
                description="Text describing how this sponsor or what the base message offers to the customer."
              /> */}
            </div>

            {/* right side section of home page displaying sponser images and description in grid-like layout */}

            <div className={`${classes["subImagesView"]} col-lg-6`}>
              <div className={classes["mainImage"]}>
                <SubImageView
                  img={images.feature2}
                  brand="dual"
                  description={
                    "Comfy and Stylish! Can you name a better combo? Don’t miss out the chance to shop Dual’s bling spring collection"
                  }
                />
              </div>
              <div className={classes["subImages"]}>
                <SubImageView
                  className={classes["SubImage-custom"]}
                  img={images.feature3}
                  brand="theá"
                  description={
                    "Indulge in the ultimate seaside glamour with Thea's exquisite collection of  women's swimwear, crafted with the finest materials and designed to make you feel confident and chic all summer long"
                  }
                />
                <SubImageView
                  className={classes["SubImage-custom"]}
                  img={images.feature4}
                  brand="riddle"
                  description={
                    "Get ready to solve the style puzzle with Riddle denim jackets the perfect fit for your fashion statement!"
                  }
                />
              </div>
            </div>
          </div>

          {/* home page coming soon section */}

          {/* <div className={classes["home__comingSoon-section"]}>
            <img src={images.comingSoonBanner} alt="coming soon banner" />
            <h4>Coming Soon</h4>
            <p>
              Text describing how this sponsor or what the base message offers
              to the customer. Text describing how this sponser or what the base
              message offers to the customer.
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Home;
