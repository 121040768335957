import React from "react";
import "./styles.scss";

const Spinner = ({ fullWidth = true }) => {
  return (
    <div class="d-flex justify-content-center">
      <div
        class={`spinner-border ${
          fullWidth ? "spinner-border-lg" : null
        }`}
        role="status"
      />
    </div>
  );
};

export default Spinner;
