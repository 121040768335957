import classes from "./ChangePassword.module.scss";
import { TextInput, PasswordInput, Button } from "../../../../../../components";

const ChangePassword = () => {
  return (
    <div className={classes["change-password__content"]}>
      <h1>change password</h1>
      <div className="row">
        <div className={`${classes["inputCol"]} col-md-12`}>
          <TextInput id="email" label="Email" placeholder="ABC@ABC.COM" />
        </div>
        <div className={`${classes["inputCol"]} col-md-12`}>
          <PasswordInput
            id="old-password"
            label="Old Password"
            placeholder="***"
          />
        </div>
        <div className={`${classes["inputCol"]} col-md-12`}>
          <PasswordInput
            id="new-password"
            label="New Password"
            placeholder="***"
          />
        </div>
        <div className={`${classes["inputCol"]} col-md-12`}>
          <PasswordInput
            id="repeat-password"
            label="Repeat Password"
            placeholder="***"
          />
        </div>
      </div>
      <div className={classes["btn-holder"]}>
        <Button className="btn-primary w-50" label="Save New Password" />
      </div>
    </div>
  );
};

export default ChangePassword;
