import classes from "./FAQsAndGuides.module.scss";
import { useEffect } from "react";

const FAQsAndGuides = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes["refundPolicy-container"]}>
      <h1>Refund and Exchange Policy</h1>
      <ol>
        <li>
          <b>Introduction</b>
          <br />
          We want you to be completely satisfied with your purchase from our
          e-commerce website. If for any reason you are not satisfied, you may
          return the item(s) within 14 days of receipt for a full refund or
          exchange. This policy applies to all items purchased from our website
        </li>
        <li>
          <b>Eligibility for Returns</b>
          <br />
          To be eligible for a return, the item(s) must be in their original
          condition, unused, and with all original tags and packaging intact. We
          reserve the right to refuse any returns that do not meet these
          criteria
        </li>
        <li>
          <b>Return Process</b>
          <br />
          To initiate a return, please contact our customer service team to
          obtain a return authorization number. You will be responsible for the
          cost of shipping the item(s) back to us, unless the item(s) was
          defective or damaged upon arrival. We recommend that you use a
          trackable shipping method and insure the package for the full value of
          the item(s)
        </li>
        <li>
          <b>Refunds</b>
          <br />
          Once we receive the returned item(s), we will inspect them and process
          your refund within 7-10 business days. We will refund the full
          purchase price of the item(s), including any taxes or shipping
          charges, to the original form of payment
        </li>
        <li>
          <b>Exchanges</b>
          <br />
          If you would like to exchange an item for a different size or color,
          please contact our customer service team to obtain a return
          authorization number and place a new order for the desired item(s). We
          will process your refund for the returned item(s) in accordance with
          our refund policy
        </li>
        <li>
          <b>Exceptions</b>
          <br />
          The following items are not eligible for return or exchange:
          personalized or custom-made items, beauty goods, perishable goods, and
          gift cards
        </li>
        <li>
          <b>Customer Service</b>
          <br />
          If you have any questions or concerns about our return policy, please
          contact our customer service team at{" "}
          <a href="mailto:contact@base-egypt.com">Base</a>
        </li>
      </ol>
      <p>
        We hope this return policy provides clarity and confidence to our
        customers when making purchases from our e-commerce website.
      </p>
    </div>
  );
};

export default FAQsAndGuides;
