import classes from "./ReferFriend.module.scss";
import { FiShare } from "react-icons/fi";

const ReferFriend = () => {
  return (
    <div className={classes["refer-friend__content"]}>
      <h1>Refer a Friend</h1>

      <div className={classes["refer-section"]}>
        <p>Points Claimed on next referral</p>
        <p>580 Points</p>
      </div>
      <div className={`${classes["refer-section"]} ${classes["code"]}`}>
        <p>Referral Code</p>
        <p>AWFX32Y</p>
        <FiShare />
      </div>
      <div className={classes["btn-holder"]}>
        {/* <Button className="btn-primary w-50" label="Save Address" /> */}
      </div>
    </div>
  );
};

export default ReferFriend;
