import classes from "./AddressList.module.scss";
import { useState, useCallback, useEffect } from "react";
import AddressListItem from "./AddressListItem/AddressListItem";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import axios from "axios";

const AddressList = () => {
  const token = localStorage.getItem("userToken");
  const [addressList, setAddressList] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchAddressList = useCallback(() => {
    axios
      .get(`${BASE_URL}/my-addresses`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedAddressList = response.data.data.map((address) => {
          return {
            addressId: address.id,
            city: address.address.city,
            country: address.address.country,
            landmark: address.address.landmark,
            phone: address.address.phone_number,
            street1: address.address.street_1,
            street2: address.address.street_2,
            customerId: address.customer_id,
          };
        });
        setAddressList([...fetchedAddressList]);
      });
  }, []);

  useEffect(() => {
    fetchAddressList();
  }, []);

  const handleDeleteAddress = (addressId) => {
    axios.delete(`${BASE_URL}/address/${addressId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const updatedAddressList = addressList.filter(
      (address) => address.addressId !== addressId
    );

    setAddressList(updatedAddressList);
  };

  return (
    <div className={classes["address-book__content"]}>
      <h1>Address Book</h1>
      <div className={classes["address-list"]}>
        {addressList.map((address, index) => (
          <AddressListItem
            addressInfo={address}
            deleteAddress={handleDeleteAddress}
            key={index}
          />
        ))}
        <h5 className={classes["newAddressLink"]}>
          <Link to={"add-update-address"} state={{ formType: "add" }}>
            <span>Add New Address</span>
            <span>
              <BsChevronRight />
            </span>
          </Link>
        </h5>
      </div>
    </div>
  );
};

export default AddressList;
