import classes from "./AddressListItem.module.scss";
import { Link } from "react-router-dom";
import { FiTrash2, FiChevronRight } from "react-icons/fi";

const AddressListItem = ({ addressInfo, deleteAddress }) => {
  return (
    <div className={classes["address__listItem"]}>
      <h5>{addressInfo.primary ? "Primary" : ""} Address</h5>
      <div className={classes["address__listItem-content"]}>
        <div>
          <p>
            {addressInfo.street1}, {addressInfo.street2}
          </p>
          <p>
            {addressInfo.city}, {addressInfo.country}
          </p>
          <p>
            {addressInfo.landmark}, {addressInfo.phone}
          </p>
        </div>
        <div className={classes["actions"]}>
          <p>
            <Link
              to={"add-update-address"}
              state={{ formType: "edit", address: addressInfo }}
            >
              Edit Address <FiChevronRight />
            </Link>
          </p>
          <FiTrash2
            onClick={() => {
              deleteAddress(addressInfo.addressId);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressListItem;
