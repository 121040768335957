import classes from "./AddNewAddress.module.scss";
import { Button, TextInput, DropdownInput } from "../../../../../components";
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "axios";

const AddNewAddress = () => {
  const token = localStorage.getItem("userToken");
  const customerId = +localStorage.getItem("customerId");
  const navigate = useNavigate();
  const location = useLocation();
  const formType = location.state.formType;
  const addressToEdit = location.state.address;
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [cities, setCities] = useState([]);

  const fetchCitiesList = useCallback(() => {
    axios.get(`${BASE_URL}/cities`).then((response) => {
      const fetchedCities = response.data.map((city) => {
        if (formType === "edit" && city.slug === addressToEdit.city) {
          setSelectedCity({
            id: city.id,
            name: city.name,
            slug: city.slug,
            shippingCost: city.shipping_cost,
          });
        }
        return {
          id: city.id,
          name: city.name,
          slug: city.slug,
          shippingCost: city.shipping_cost,
        };
      });
      setCities([...fetchedCities]);
    });
  }, []);

  const fetchUserId = useCallback(() => {
    axios
      .get(`${BASE_URL}/me`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((Response) => {
        localStorage.setItem("customerId", Response.data.id);
        window.dispatchEvent(new Event("storage"));
      });
  }, []);

  useEffect(() => {
    fetchCitiesList();
    fetchUserId();
    if (formType === "edit") {
      setStreet1(addressToEdit.street1);
      setStreet2(addressToEdit.street2);
      setTelephone(addressToEdit.phone);
      setLandmark(addressToEdit.landmark);
    }
  }, []);

  // state snippits for data
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [telephone, setTelephone] = useState("");
  const [telephoneHint, setTelephoneHint] = useState("");
  const [landmark, setLandmark] = useState("");

  // change handlers

  const telephoneInputHandler = (e) => {
    const numInputPattern = /^[0-9]*$/;

    if (numInputPattern.test(e.target.value)) {
      setTelephone(e.target.value);
    }
  };

  const addNewAddressHandler = (e) => {
    e.preventDefault();
    const telephonePattern = /^(010|011|012|015)\d{8}$/;

    if (telephonePattern.test(telephone)) {
      setTelephoneHint("");
    } else {
      setTelephoneHint("enter a valid mobile number");
      return;
    }

    if (formType === "add") {
      axios.post(
        `${BASE_URL}/address`,
        {
          title: "Shipping",
          type: "shipping",
          customer_id: customerId,
          address: {
            city: selectedCity.slug,
            country: "Egypt",
            street_1: street1,
            street_2: street2 || "",
            landmark: landmark,
            phone_number: telephone,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } else if (formType === "edit") {
      axios.put(
        `${BASE_URL}/address/${addressToEdit.addressId}`,
        {
          title: "Shipping",
          type: "shipping",
          customer_id: customerId,
          address: {
            city: selectedCity.slug,
            country: "Egypt",
            street_1: street1,
            street_2: street2,
            landmark: landmark,
            phone_number: telephone,
          },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    }

    navigate("..", { state: { addressesUpdated: true } });
  };

  return (
    <div className={classes["addAddress-container"]}>
      <form onSubmit={addNewAddressHandler}>
        <div className="row">
          <div className={`${classes["inputCol"]} col-md-12`}>
            <TextInput
              value={street1}
              onChange={(e) => setStreet1(e.target.value)}
              id="address1"
              label="Street 1"
              placeholder="ADDRESS"
            />
          </div>
          <div className={`${classes["inputCol"]} col-md-12`}>
            <TextInput
              value={street2}
              onChange={(e) => setStreet2(e.target.value)}
              id="address2"
              label="Street 2"
              required="no"
              placeholder="ADDRESS"
            />
          </div>
          <div className={`${classes["inputCol"]} col-md-12`}>
            <TextInput
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              id="landmark"
              label="Landmark"
              required="no"
              placeholder="LANDMARK"
            />
          </div>
          <div className={`${classes["inputCol"]} col-md-12`}>
            <DropdownInput
              options={cities}
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.value)}
              id="city"
              label="City"
              placeholder="SELECT CITY"
            />
          </div>
          <div className={`${classes["inputCol"]} col-md-12`}>
            <div className="row">
              <div className={`${classes["inputCol"]} col-md-6`}>
                <TextInput
                  value={telephone}
                  onChange={telephoneInputHandler}
                  id="telephone"
                  label="Telephone"
                  placeholder="01234567890"
                  hint={telephoneHint}
                />
              </div>
              <div className={`${classes["inputCol"]} col-md-6`}>
                <TextInput id="country" label="Country" value="Egypt" />
              </div>
            </div>
          </div>
          <div className={`${classes["inputCol"]} col-md-12`}>
            <div className={classes["btn-holder"]}>
              <Button
                type="submit"
                className="btn-primary w-50"
                label="Save Address"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewAddress;
