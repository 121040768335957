import classes from "./List.module.scss";
import ListItem from "./ListItem/ListItem";
import { Button } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BsBasket3 } from "react-icons/bs";
import { useSelector } from "react-redux";

const List = () => {
  const navigate = useNavigate();
  const {items} = useSelector(state => state.cart)
  const [bagList, setBagList] = useState(items || []);
  useEffect(() => {
    setBagList(items)
  }, [items]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let total = 0;
    for (const item of bagList) {
      if (item.type === "simple") {
        if (item.product.simpleSalePrice) {
          total += item.quantity * item.product.simpleSalePrice;
        } else {
          total += item.quantity * item.product.simplePrice;
        }
      } else if (item.type === "variable") {
        if (item.salePrice) {
          total += item.quantity * item.salePrice;
        } else {
          total += item.quantity * item.price;
        }
      }
    }
    setTotalPrice(total);
    localStorage.setItem("subTotal", total);
  }, [bagList]);
  
  const proceedToCheckout = () => {
    navigate("checkout/address", { state: { addressesUpdated: true } });
  };

  return (
    <div className={classes["cart__list-holder"]}>
      <div className={classes["cart__list-content"]}>
        <h1>Bag</h1>
        <div className={classes["cart__list"]}>
          {bagList.length === 0 && (
            <div className={classes["emptyCart"]}>
              <BsBasket3 />
              <h4>
                <Link to='/'>Continue Shopping</Link>
              </h4>
            </div>
          )}
          {bagList.map((bagItem, index) => (
            <ListItem
              itemData={bagItem}
              key={bagItem.id + index}
            />
          ))}
        </div>
        {bagList.length > 0 && (
          <div className={classes["card__total"]}>
            <h3>Total</h3>
            <p>
              <span>Sub Total</span>
              <span>{totalPrice} EGP</span>
            </p>

            <div className={classes["btn-holder"]}>
              <Button
                className="btn-primary w-50"
                label="Checkout"
                onClick={proceedToCheckout}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default List;
