import classes from "./Checkout.module.scss";
import { Steps } from "primereact/steps";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const Checkout = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const items = [
    {
      label: "Address",
    },
    {
      label: "Payment",
    },
    {
      label: "Summary",
    },
  ];

  return (
    <div className={classes["cart__checkout-holder"]}>
      <div className={classes["cart__checkout-content"]}>
        <h1>Checkout</h1>

        <div className={classes["checkout__stepper"]}>
          <div className={classes["checkout__stepper-head"]}>
            <Steps model={items} activeIndex={activeIndex}  />
          </div>
          <div className={classes["checkout__stepper-step"]}>
            <Outlet context={[setActiveIndex]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
