import { useState, useEffect } from "react";
import classes from "./Navbar.module.scss";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ navOptions, type, className, closeMenu }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(navOptions);
  }, [navOptions]);

  const navigate = useNavigate();

  const navToLink = (option) => {
    navigate(`../products/${option.TypeSlug}`, {
      state: { typeName: option.TypeName, filterType: "productType" },
    });
    if (closeMenu) {
      closeMenu();
    }
  };

  const navToFilter = (option) => {
    navigate(`../products/${option.slug}`, {
      state: { typeName: option.TypeName, filterType: "subMenufilter" },
    });
    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <nav className={`${classes["base__navbar"]} ${className}`}>
      <ul className={classes["base__navbar-links"]}>
        {type === "CategoryMenu" && (
          <>
            <li>
              <Link
                onClick={() => {
                  closeMenu();
                }}
                to="/brands"
              >
                Brands
              </Link>
            </li>
            {options.map((option, index) => (
              <li
                className={
                  option.TypeName === "SALE" ? classes["highlighed"] : ""
                }
                onClick={() => navToLink(option)}
                key={index}
              >
                {option.TypeName}
              </li>
            ))}
          </>
        )}
        {type === "subMenuFilter" &&
          options.map((option, index) => (
            <li onClick={() => navToFilter(option)} key={index}>
              {option.TypeName}
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Navbar;
