import { useState, useEffect, useCallback } from "react";
import { Navbar, Search, Button } from "../../components";
import { BsBasket3, BsHeart } from "react-icons/bs";
import { RxPerson } from "react-icons/rx";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { Dialog } from "primereact/dialog";

import classes from "./Header.module.scss";
import UserDialog from "./components/UserDialog/UserDialog";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = ({
  isDialogVisible,
  setisDialogVisible,
  dialogType,
  setDialogType,
  setActiveButton,
  activeButton,
}) => {
  const [token, setToken] = useState(null);
  const {items} = useSelector(state => state.cart)

  const [toggleMenu, setToggleMenu] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isUserLogged")
  );

  const filters = [
    { TypeName: "All", slug: "all" },
    { TypeName: "Unisex", slug: "unisex" },
    { TypeName: "Women", slug: "women" },
    { TypeName: "Men", slug: "men" },
  ];
  const [types, setTypes] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchTypes = useCallback(() => {
    axios.get(`${BASE_URL}/types`).then((response) => {
      const fetchedTypes = response.data.map((type) => {
        return {
          TypeId: type.id,
          TypeName: type.name,
          TypeSlug: type.slug,
        };
      });
      setTypes(fetchedTypes);
    });
  }, []);

  useEffect(() => {
    fetchTypes();

    const handleStorageChange = () => {
      setIsLoggedIn(localStorage.getItem("isUserLogged"));
      setToken(localStorage.getItem("usertoken"));
    };
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [fetchTypes]);

  const displayConfirmDialog = () => {
    setShowConfirmationDialog(true);

    // setTimeout(() => {
    //   setShowConfirmationDialog(false);
    // }, 5000);
  };

  const closeMenu = () => {
    setToggleMenu(false);
  };

  return (
    <header className={classes["base__header"]}>
      <div className={`${classes["base__header-content"]} container`}>
        {/* header top content containing logo, subMenu, and header-actions */}
        <div className={classes["header__content-top"]}>
          <div className={classes["top-leftSide"]}>
            {/* <Navbar navOptions={filters} type="subMenuFilter" /> */}
          </div>
          <h1 className={classes["brand"]}>
            <Link to="/">BASE</Link>
          </h1>
          <div className={classes["header__actions"]}>
            {isLoggedIn ? (
              <div className={classes["header__actions-icons"]}>
                <Link to="./user-settings/settings-list/wishlist">
                  <BsHeart />
                </Link>
                <Link to="./user-settings">
                  <RxPerson />
                </Link>
                <Link className={classes["cartIcon"]} to="./cart">
                  <small>{items.length || 0}</small>
                  <BsBasket3 />
                </Link>
                <AiOutlineMenu
                  className={classes["openMenu"]}
                  onClick={() => setToggleMenu(true)}
                />
              </div>
            ) : (
              <div className={classes["header__actions-buttons"]}>
                <Button
                  onClick={() => {
                    setisDialogVisible(true);
                    setDialogType("login");
                    setActiveButton(0);
                  }}
                  className="btn-secondary"
                  label="Sign In"
                />
                <Button
                  onClick={() => {
                    setisDialogVisible(true);
                    setDialogType("signup");
                    setActiveButton(1);
                  }}
                  className="btn-primary"
                  label="Join Us"
                />
                <AiOutlineMenu
                  className={classes["openMenu"]}
                  onClick={() => setToggleMenu(true)}
                />
              </div>
            )}
          </div>
        </div>

        {/* header bottom content containing categories, search field */}
        <div className={classes["header__content-bottom"]}>
          <div className={classes["navbar-view"]}>
            <Navbar
              navOptions={types}
              type="CategoryMenu"
              closeMenu={closeMenu}
            />
          </div>
          <div className={classes["SearchField"]}>
            {/* <Search label="Search" /> */}
          </div>
        </div>
      </div>
      {/* toggle menu for smaller screens containing search bar and navs */}
      {toggleMenu && (
        <div className={classes["base__header-menu"]}>
          <AiOutlineClose onClick={() => setToggleMenu(false)} />
          {/* <div className={classes["menu__searchField"]}>
            <Search />
          </div> */}
          {/* <Navbar
            className={classes["menu__subMenu"]}
            navOptions={filters}
            type="subMenuFilter"
            closeMenu={closeMenu}
          /> */}
          <Navbar
            className={classes["menu_categories"]}
            navOptions={types}
            type="CategoryMenu"
            closeMenu={closeMenu}
          />
        </div>
      )}

      {/* sale bar view */}
      {/* <div className={classes["base__header-sale"]}>
        <p>
          SALE <span>Up to 60% OFF</span>
        </p>
      </div> */}

      {/* login / signup dialog */}

      <UserDialog
        changeStatus={setIsLoggedIn}
        isVisible={isDialogVisible}
        setIsDialogVisible={setisDialogVisible}
        setIsVisible={setisDialogVisible}
        type={dialogType}
        changeType={setDialogType}
        showConfirmation={displayConfirmDialog}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
      />

      {/* confirm registration dialog */}
      <Dialog
        header=""
        visible={showConfirmationDialog}
        style={{ width: "30vw" }}
        breakpoints={{
          "1199px": "40vw",
          "960px": "45vw",
          "641px": "70vw",
          "575px": "90vw",
        }}
        onHide={() => setShowConfirmationDialog(false)}
      >
        <h2>Welcome To Base</h2>
        <p>a confirmation mail has been sent to your Email</p>
      </Dialog>
    </header>
  );
};

export default Header;
