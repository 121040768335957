import classes from "./Payment.module.scss";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  TextInput,
  PasswordInput,
  CalendarInput,
} from "../../../../../components";
import { useOutletContext, useNavigate } from "react-router-dom";
import axios from "axios";

const Payment = () => {
  const shippingCity = JSON.parse(localStorage.getItem("selectedAddress")).city;
  const [shippingCost, setShippingCost] = useState(0);
  const subTotal = +localStorage.getItem("subTotal");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchCityShipping = useCallback(() => {
    axios
      .get(`${BASE_URL}/cities/${shippingCity}`)
      .then((response) => {
        setShippingCost(response.data.shipping_cost);
        localStorage.setItem("shippingCost", response.data.shipping_cost);
      });
  }, []);

  const [payNow, setPayNow] = useState(false);
  const [onDelivery, setOnDelivery] = useState(true);
  const [setActiveIndex] = useOutletContext();
  const navigate = useNavigate();

  const selectPayment = (paymentType) => {
    if (paymentType === "onDelivery") {
      setOnDelivery(true);
      setPayNow(false);
      localStorage.setItem("paymentMethod", "Cash on Delivery");
    } else if (paymentType === "payNow") {
      setOnDelivery(false);
      setPayNow(true);
    }
  };

  useEffect(() => {
    setActiveIndex(1);
    fetchCityShipping();
    localStorage.setItem("paymentMethod", "Cash on Delivery");
  }, [fetchCityShipping]);

  const handlePaymentMethod = () => {
    navigate("../summary");
  };

  return (
    <div className={classes["payment-container"]}>
      <div className={classes["payment-tabs"]}>
        {/* <h2
          onClick={() => selectPayment("payNow")}
          className={payNow ? classes["active"] : ""}
        >
          Pay Now
        </h2> */}
        <h2
          // onClick={() => selectPayment("onDelivery")}
          className={onDelivery ? classes["active"] : ""}
        >
          Payment Method
        </h2>
      </div>
      {payNow && (
        <div className={classes["cardInfo__form"]}>
          <div className="row">
            <div className={`${classes["inputCol"]} col-md-12`}>
              <TextInput
                id="cardname"
                label="Name on Card"
                placeholder="NAME"
              />
            </div>
            <div className={`${classes["inputCol"]} col-md-12`}>
              <TextInput
                id="cardNumber"
                label="Card Number"
                placeholder="0000 1111 2222 3333"
              />
            </div>
            <div className={`${classes["inputCol"]} col-md-6`}>
              <PasswordInput id="ccv" label="CCV" placeholder="***" />
            </div>
            <div className={`${classes["inputCol"]} col-md-6`}>
              <CalendarInput
                id="expdate"
                label="Expiry Date"
                placeholder="MM/YY"
              />
            </div>
          </div>
        </div>
      )}

      <div className={classes["total__section"]}>
        <p>
          <span>Sub Total</span>
          <span>{subTotal} EGP</span>
        </p>
        <p>
          <span>Shipping</span>
          <span>{shippingCost} EGP</span>
        </p>
        <p>
          <span>Total</span>
          <span>{subTotal + shippingCost} EGP</span>
        </p>
      </div>
      {onDelivery && (
        <div className={classes["onDelivery__option-Btns"]}>
          <Button
            onClick={handlePaymentMethod}
            className="btn-primary w-50"
            label="Cash on Delivery"
          />
          {/* <Button className="btn-secondary w-50" label="Card on Delivery" /> */}
        </div>
      )}
      {payNow && (
        <div className={classes["payNow__option-Btns"]}>
          <Button className="btn-primary" label="Proceed" />
        </div>
      )}
    </div>
  );
};

export default Payment;
