// src/rootReducer.js

import { combineReducers } from '@reduxjs/toolkit';
import cartSlice from './slices/cart.slice';
import productSlice from './slices/product.slice';

const rootReducer = combineReducers({
  cart: cartSlice.reducer,
  product: productSlice.reducer
});

export default rootReducer;