import classes from "./Summary.module.scss";
import { useEffect, useCallback } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Button } from "../../../../../components";
import axios from "axios";
import { useSelector } from "react-redux";

const Summary = () => {
  const token = localStorage.getItem("userToken");
  const {items: bagList } = useSelector(state => state.cart)
  const [setActiveIndex] = useOutletContext();
  const selectedAddress = JSON.parse(localStorage.getItem("selectedAddress"));
  const selectedPaymentMethod =
    localStorage.getItem("paymentMethod") === "Cash on Delivery"
      ? "CASH_ON_DELIVERY"
      : "";
  const subTotal = +localStorage.getItem("subTotal");
  const shippingFees = +localStorage.getItem("shippingCost");
  const customerId = localStorage.getItem("customerId");
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const orderList = bagList.map(
    (listItem) => {
      if (listItem.type === "simple") {
        return {
          product_id: listItem.id,
          order_quantity: listItem.quantity,
          unit_price: listItem.product.simpleSalePrice
            ? listItem.product.simpleSalePrice
            : listItem.product.simplePrice,
          subtotal:
            +listItem.quantity *
            +(listItem.product.simpleSalePrice
              ? listItem.product.simpleSalePrice
              : listItem.product.simplePrice),
        };
      } else if (listItem.type === "variable") {
        return {
          product_id: listItem.id,
          order_quantity: listItem.quantity,
          unit_price: listItem.salePrice ? listItem.salePrice : listItem.price,
          variation_option_id: listItem.variantId,
          subtotal:
            +listItem.quantity *
            +(listItem.salePrice ? listItem.salePrice : listItem.price),
        };
      }
    }
  );

  useEffect(() => {
    setActiveIndex(2);
    fetchUserId();
  }, []);

  const fetchUserId = useCallback(() => {
    axios
      .get(`${BASE_URL}/me`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((Response) => {
        localStorage.setItem("customerId", Response.data.id);
        window.dispatchEvent(new Event("storage"));
      });
  }, []);

  const confirmOrder = () => {
    axios
      .post(
       `${BASE_URL}/orders`,
        {
          customer_id: customerId,
          amount: subTotal + shippingFees,
          paid_total: subTotal + shippingFees,
          total: subTotal + shippingFees,
          payment_gateway: selectedPaymentMethod,
          products: orderList,
          shipping_address: {
            country: selectedAddress.country,
            city: selectedAddress.city,
            street_1: selectedAddress.street1,
            street_2: selectedAddress.street2,
            landmark: selectedAddress.landmark,
          },
          customer_contact: selectedAddress.phone,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((Response) => {
        localStorage.removeItem("toAddToCart");
        localStorage.removeItem("shippingCost");
        localStorage.removeItem("subTotal");
        localStorage.removeItem("paymentMethod");
        localStorage.setItem("totalQuantity", 0);
        window.dispatchEvent(new Event("storage"));

        navigate("/base-message/confirmOrder", {
          state: { orderNumber: Response.data.tracking_number },
          replace: true,
        });
      });
  };

  return (
    <div className={"summary__container"}>
      <div className={classes["summary__address"]}>
        <h5>ADDRESS</h5>
        <div className={classes["summary__address-content"]}>
          <div>
            <p>
              {selectedAddress.street1}, {selectedAddress.street2}
            </p>
            <p>
              {selectedAddress.city}, {selectedAddress.country}
            </p>
            <p>
              {selectedAddress.landmark}, {selectedAddress.phone}
            </p>
          </div>
        </div>
      </div>
      <div className={classes["summary__paymentMethod"]}>
        <h5>Payment Method</h5>
        <p>{localStorage.getItem("paymentMethod")}</p>
      </div>
      <div className={classes["summary__productList"]}>
        {bagList.map((listItem) => (
          <div key={listItem.id} className={classes["list__item"]}>
            <div className={classes["list__item-leftSide"]}>
              <div className={classes["leftSide-img"]}>
                <img src={listItem.product.image} alt="cart-item" />
              </div>
            </div>
            <div className={classes["list__item-rightSide"]}>
              <div className={classes["rightSide-info"]}>
                <p>{listItem.product.name}</p>
                <p>{listItem.product.brand}</p>
              </div>
              <div className={classes["pq"]}>
                <p className={classes["price"]}>
                  <p>Price :</p>
                  <p>
                    {listItem.type === "simple" &&
                      listItem.product.simpleSalePrice && (
                        <>
                          <span className={classes["original"]}>
                            {listItem.product.simplePrice} EGP
                          </span>
                          <span className={classes["sale"]}>
                            {listItem.product.simpleSalePrice} EGP
                          </span>
                        </>
                      )}
                    {listItem.type === "simple" &&
                      !listItem.product.simpleSalePrice &&
                      `${listItem.product.simplePrice} EGP`}
                    {listItem.type === "variable" &&
                      !listItem.salePrice &&
                      listItem.price}
                    {listItem.type === "variable" && listItem.salePrice && (
                      <>
                        <span className={classes["original"]}>
                          {listItem.price} EGP
                        </span>
                        <span className={classes["sale"]}>
                          {listItem.salePrice} EGP
                        </span>
                      </>
                    )}
                  </p>
                </p>
                <p>Quantity : {listItem.quantity}</p>
                {listItem.type === "variable" && (
                  <p>
                    <span>Size : </span>
                    <span>{listItem.size}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes["summary__total"]}>
        <p>
          <span>Sub Total</span>
          <span>{subTotal} EGP</span>
        </p>
        <p>
          <span>Shipping</span>
          <span>{shippingFees} EGP</span>
        </p>
        <p>
          <span>Total</span>
          <span>{subTotal + shippingFees} EGP</span>
        </p>
      </div>
      <div className={classes["summary__checkoutBtn"]}>
        <Button
          onClick={confirmOrder}
          className="btn-primary"
          label="Place Order"
        />
      </div>
    </div>
  );
};

export default Summary;
