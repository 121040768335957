import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button, CalendarInput, TextInput } from "../../../../../../components";
import classes from "./Details.module.scss";

const Details = () => {
  const [deleteAccDialog, setdeleteAccDialog] = useState(false);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const deleteAccount = () => {
    axios.post(`${BASE_URL}/logout`);
    localStorage.clear();
    window.dispatchEvent(new Event("storage"));
    navigate("/");
  };

  return (
    <div className={classes["user-details__content"]}>
      <h1>Details & Password</h1>

      <div className="row">
        <div className={`${classes["inputCol"]} col-md-12`}>
          <TextInput
            id="firstName"
            label="First Name"
            placeholder="FIRST NAME"
          />
        </div>
        <div className={`${classes["inputCol"]} col-md-12`}>
          <TextInput id="lastName" label="Last Name" placeholder="LAST NAME" />
        </div>
        <div className={`${classes["inputCol"]} col-md-12`}>
          <TextInput id="email" label="Email" placeholder="ABC@ABC.COM" />
        </div>
        <div className={`${classes["inputCol"]} col-md-12`}>
          <CalendarInput
            id="birthDate"
            label="Date of Birth"
            placeholder="DD/MM/YY"
          />
        </div>
      </div>
      <div className={classes["btn-holder"]}>
        <Link to={"change-password"}>
          <Button className="btn-primary w-50" label="Change Password" />
        </Link>
      </div>
      <div className={classes["delete-account"]}>
        <h4>Delete Account</h4>
        <p>
          If you’re offered a seat on a rocket ship, don’t ask what seat! Just
          get on board and move the sail towards the destination.
        </p>
        <div className={classes["actions"]}>
          <p onClick={() => setdeleteAccDialog(true)}>Delete Account</p>
          <p>Privacy Policy </p>
        </div>
      </div>

      <Dialog
        header="Delete Your Account"
        visible={deleteAccDialog}
        style={{ width: "30vw" }}
        breakpoints={{
          "1199px": "40vw",
          "960px": "45vw",
          "641px": "70vw",
          "575px": "90vw",
        }}
        onHide={() => setdeleteAccDialog(false)}
      >
        <h2>Are You Sure that You want to delete Your Account ?</h2>
        <Button
          onClick={() => {
            deleteAccount();
          }}
          className="btn-primary"
          label="Confirm"
        />
        <Button
          onClick={() => {
            setdeleteAccDialog(false);
          }}
          className="btn-secondary"
          label="Cancel"
        />
      </Dialog>
    </div>
  );
};

export default Details;
