import { Password } from "primereact/password";
import classes from "./PasswordInput.module.scss";

const PasswordInput = (props) => {
  let hints;
  if (props.hint) {
    hints = props.hint.split(".");
    console.log(hints);
  }
  return (
    <span className={classes["passwordField"]}>
      <label htmlFor={props.id}>
        {props.label}
        <span>*</span>
      </label>
      <Password
        toggleMask
        inputId={props.id}
        feedback={false}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        aria-describedby="password-help"
        required
      />
      {props.hint &&
        <ul>
          {hints.map((hint, index) => (
          <li key={index} id="password-help">
            {hint}
          </li>
        ))}
        </ul>
        }
    </span>
  );
};

export default PasswordInput;
