import { useCallback, useEffect, useState } from "react";
import classes from "./OrderDetails.module.scss";
import OrderItem from "./OrderItem/OrderItem";
import { useParams } from "react-router-dom";
import axios from "axios";

const OrderDetails = () => {
  const token = localStorage.getItem("userToken");
  let { orderNumber } = useParams();

  const [orderData, setOrderData] = useState([]);
  const [orderAddress, setOrderAddress] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchOrderData = useCallback(() => {
    axios
      .get(`${BASE_URL}/orders/${orderNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedOrderData = response.data;
        // console.log(fetchedOrderData);
        const orderData = {
          orderDate: fetchedOrderData.created_at.slice(0, 10),
          orderNumber: fetchedOrderData.tracking_number,
          orderStatus: fetchedOrderData.order_status,
          orderImage: fetchedOrderData.products[0].image.original,
          orderAmount: fetchedOrderData.amount,
          orderShippingFees: fetchedOrderData.delivery_fee,
          orderTotal: fetchedOrderData.paid_total,
        };
        setOrderData(orderData);
        setOrderAddress(fetchedOrderData.shipping_address);
        setOrderProducts(fetchedOrderData.products);
      });
  }, []);

  useEffect(() => {
    fetchOrderData();
  }, []);

  return (
    <div className={classes["orderDetails__content"]}>
      <h1>Tracking</h1>
      <div className={classes["order-itemsList"]}>
        {orderProducts.map((product) => (
          <OrderItem productData={product} />
        ))}
      </div>
      <div className={classes["order-details"]}>
        <div>
          <p>Order Date</p>
          <p>{orderData.orderDate}</p>
        </div>
        <div>
          <p>Order Number</p>
          <p>{orderData.orderNumber}</p>
        </div>
        <div>
          <p>Order Status</p>
          <p>{orderData.orderStatus}</p>
        </div>
      </div>
      <div className={classes["delivery-address"]}>
        <h4>Delivery Address</h4>
        <p>
          {orderAddress.street_1}, {orderAddress.street_2}
        </p>
        <p>{orderAddress.landmark}</p>
        <p>
          {orderAddress.city}, {orderAddress.country}
        </p>
      </div>
      <div className={classes["order-summary"]}>
        <h4>Summary</h4>
        <p>
          <span>Sub Total</span>
          <span>{orderData.orderAmount} EGP</span>
        </p>
        <p>
          <span>Shipping</span>
          <span>{orderData.orderShippingFees} EGP</span>
        </p>
        <p>
          <span>Total</span>
          <span>{orderData.orderTotal} EGP</span>
        </p>
      </div>
      <div className={classes["contact"]}>
        <h4>Contact Us</h4>
        <div className={classes["links"]}>
          <p>
            <a href="mailto:contact@base-egypt.com">Email Us</a>
          </p>
          <p>
            <a href="tel:01288884007">Phone</a>
          </p>
        </div>
      </div>
      <p className="mt-3">Available from 1PM - 5pm Sunday - Thursday</p>
    </div>
  );
};

export default OrderDetails;
