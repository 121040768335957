import "./App.scss";
import { Header, Footer } from "./shared";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import { Provider } from 'react-redux'
import {persistor, store} from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import {
  Home,
  Brands,
  Products,
  ProductView,
  UserSettings,
  Cart,
  CartList,
  Checkout,
  CheckoutAddNewAddress,
  CheckoutAddress,
  CheckoutPayment,
  CheckoutSummary,
  BaseMessage,
  SettingsMainPage,
  SettingsList,
  About,
  AddressBook,
  ContactUs,
  DetailsAndPassword,
  FAQsAndGuides,
  JoinAsSeller,
  OrdersAndReturns,
  PrivacyPolicy,
  ReferFriend,
  TermsAndConditions,
  Wallet,
  Wishlist,
  OrderAndReturnsList,
  OrdersAndReturnsDetails,
  UserDetails,
  ChangePassword,
  AddressList,
  AddressForm,
} from "./pages";
import ScrollTop from "./components/ScrollTop";

function App() {
  const [isDialogVisible, setisDialogVisible] = useState(false);
  const [dialogType, setDialogType] = useState("login");
  const [activeButton, setActiveButton] = useState(0);

  const showDialog = () => {
    setDialogType("login");
    setisDialogVisible(true);
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="base__App">
          <BrowserRouter>
            <Header
              isDialogVisible={isDialogVisible}
              setisDialogVisible={setisDialogVisible}
              dialogType={dialogType}
              setDialogType={setDialogType}
              setActiveButton={setActiveButton}
              activeButton={activeButton}
            />
            <ScrollTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="brands" element={<Brands />} />
              <Route path="products/:type" element={<Products />} />
              <Route
                path="productView/:productID"
                element={<ProductView showDialog={showDialog} />}
              />
              <Route path="user-settings" element={<UserSettings />}>
                <Route path="" element={<SettingsMainPage />}></Route>
                <Route path="settings-list" element={<SettingsList />}>
                  <Route path="orders-and-returns" element={<OrdersAndReturns />}>
                    <Route path="" element={<OrderAndReturnsList />} />
                    <Route
                      path="details/:orderNumber"
                      element={<OrdersAndReturnsDetails />}
                    />
                  </Route>
                  <Route path="wallet" element={<Wallet />} />
                  <Route
                    path="details-and-password"
                    element={<DetailsAndPassword />}
                  >
                    <Route path="" element={<UserDetails />} />
                    <Route path="change-password" element={<ChangePassword />} />
                  </Route>
                  <Route path="address-book" element={<AddressBook />}>
                    <Route path="" element={<AddressList />} />
                    <Route path="add-update-address" element={<AddressForm />} />
                  </Route>
                  <Route path="refer-a-friend" element={<ReferFriend />} />
                  <Route path="wishlist" element={<Wishlist />} />
                  <Route path="about" element={<About />} />
                  <Route
                    path="terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="faqs-and-guides" element={<FAQsAndGuides />} />
                  <Route path="contact-us" element={<ContactUs />} />
                  <Route path="join-as-seller" element={<JoinAsSeller />} />
                </Route>
              </Route>
              <Route path="cart" element={<Cart />}>
                <Route path="" element={<CartList />} />
                <Route path="checkout" element={<Checkout />}>
                  <Route path="address">
                    <Route path="" element={<CheckoutAddress />} />
                    <Route
                      path="add-new-address"
                      element={<CheckoutAddNewAddress />}
                    />
                  </Route>
                  <Route path="payment" element={<CheckoutPayment />} />
                  <Route path="summary" element={<CheckoutSummary />} />
                </Route>
              </Route>
              <Route path="base-message/:messageType" element={<BaseMessage />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
