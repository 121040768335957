/* eslint-disable react-hooks/exhaustive-deps */
import classes from "./Products.module.scss";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { Button } from "../../components";
import { ProductCard } from "../../components";
import { FilterMenu, RefineMenu } from "../../shared";
import { images } from "../../constants";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";

const Products = () => {
  const token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(true);
  const [toggleFilterMenu, setToggleFilterMenu] = useState(false);
  const [products, setProducts] = useState([]);
  const [pagesLinks, setPagesLinks] = useState([]);
  const [lthProducts, setLthProducts] = useState([]);
  const [htlPorducts, setHtlPorducts] = useState([]);
  const [typeToShow, setTypeToShow] = useState("products");
  let { type } = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // for prevent react paginate behavior when rendering
  let header = type || location.state?.typeName;
  let pageType = location.state?.filterType;
  let shopId = location.state?.shopId;

  const fetchProducts = useCallback(() => {
    setLoading(true);
    localStorage.removeItem("currentPage");
    if (pageType === "productType") {
      axios
        .get(`${BASE_URL}/products?search=type.slug:${type}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const fetchedProducts = response.data.data;
          setProducts(fetchedProducts);
          setLoading(false);
          setPagesLinks(response.data.links.slice(1, -1));
        });
    } else if (pageType === "subMenufilter") {
      if (type === "all") {
        axios
          .get(`${BASE_URL}/products`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const fetchedProducts = response.data.data;
            setProducts(fetchedProducts);
            setLoading(false);
            setPagesLinks(response.data.links.slice(1, -1));
          });
      } else {
        axios
          .get(`${BASE_URL}/products?search=tags.slug:${type}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const fetchedProducts = response.data.data;
            setProducts(fetchedProducts);
            setLoading(false);
          });
      }
    } else if (pageType === "brand") {
      axios
        .get(`${BASE_URL}/products?search=shop_id:${shopId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const fetchedProducts = response.data.data;
          setProducts(fetchedProducts);
          setLoading(false);
          setPagesLinks(response.data.links.slice(1, -1));
        });
    }
  }, [type]);

  const fetchFilteredProducts = useCallback(
    (brands, categories) => {
      if (["all", "unisex", "men", "women"].includes(type)) {
        if (brands && categories === "") {
          axios
            .get(
              `${BASE_URL}/products?search=shop_id:${brands}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              const fetchedProducts = response.data.data;
              setProducts(fetchedProducts);
              setLoading(false);
            });
        }
        if (categories && brands === "") {
          axios
            .get(
              `${BASE_URL}/products?search=categories.slug:${categories}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              const fetchedProducts = response.data.data;
              setProducts(fetchedProducts);
              setLoading(false);
            });
        }
        if (brands && categories) {
          axios
            .get(
              `${BASE_URL}/products?searchJoin=and&search=categories.slug:${categories};shop_id:${brands}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              const fetchedProducts = response.data.data;
              setProducts(fetchedProducts);
              setLoading(false);
            });
        }
      } else {
        if (brands && categories === "") {
          axios
            .get(
              `${BASE_URL}/products?searchJoin=and&search=shop_id:${brands};type.slug:${type}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              const fetchedProducts = response.data.data;
              setProducts(fetchedProducts);
              setLoading(false);
            });
        }
        if (categories && brands === "") {
          axios
            .get(
              `${BASE_URL}/products?search=type.slug:${type}&searchJoin=and&search=categories.slug:${categories}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              const fetchedProducts = response.data.data;
              setProducts(fetchedProducts);
              setLoading(false);
            });
        }
        if (brands && categories) {
          axios
            .get(
              `${BASE_URL}/products?search=type.slug:${type}&searchJoin=and&search=categories.slug:${categories};shop_id:${brands}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              const fetchedProducts = response.data.data;
              setProducts(fetchedProducts);
              setLoading(false);
            });
        }
      }
    },
    [type]
  );

  const getFilterCriteria = (brands = [], categories = []) => {
    if (brands.length === 0 && categories.length === 0) {
      fetchProducts();
      return;
    }
    const brandsCriteria = brands?.join(",");
    const categoriesCriteria = categories?.join(",");
    fetchFilteredProducts(brandsCriteria, categoriesCriteria);
  };

  const sortPriceLTH = () => {
    const sortedProd = [...products];
    const sorted = sortedProd.sort((a, b) => {
      const priceA = a.price || a.min_price;
      const priceB = b.price || b.min_price;
      return priceA - priceB;
    });
    setLthProducts(sorted);
    setTypeToShow("lth");
  };

  const sortPriceHTL = () => {
    const sortedProd = [...products];
    const sorted = sortedProd.sort((a, b) => {
      const priceA = a.price || a.min_price;
      const priceB = b.price || b.min_price;
      return priceB - priceA;
    });
    setHtlPorducts(sorted);
    setTypeToShow("htl");
  };

  const handlePageClick = (page) => {
    const link = pagesLinks[page.selected]
      ? pagesLinks[page.selected].url
      : null;
    if (link) {
      localStorage.setItem(
        "currentPage",
        JSON.stringify({ page: page.selected, link, type })
      );
      axios
        .get(link, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const fetchedProducts = response.data.data;
          setProducts(fetchedProducts);
          setLoading(false);
        })
        .then(() => {
          window.scrollTo(0, 0);
        });
    }
  };

  useEffect(() => {
    const storedPage = JSON.parse(localStorage.getItem("currentPage"));
    if (storedPage?.type === type) {
      if (+storedPage?.page && storedPage?.link) {
        setCurrentPage(+storedPage.page);
        axios
          .get(storedPage.link, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const fetchedProducts = response.data.data;
            setPagesLinks(response.data.links.slice(1, -1));
            setProducts(fetchedProducts);
            setLoading(false);
          })
          .then(() => {
            window.scrollTo(0, 0);
          });
      } else {
        fetchProducts();
      }
    } else {
      fetchProducts();
    }
  }, [fetchProducts, type]);

  return (
    <div className={classes["base__products"]}>
      <div className="container">
        <div className={`${classes["base__products-content"]} row`}>
          {/* left side filter section */}
          <div className={"col-lg-3 col-md-4"}>
            <RefineMenu
              setOriginal={() => setTypeToShow("products")}
              sortLowToHigh={sortPriceLTH}
              sortHighToLow={sortPriceHTL}
              className={classes["products-filter"]}
            />
            <FilterMenu
              type={type}
              passFilters={getFilterCriteria}
              className={classes["products-filter"]}
            />
          </div>

          {/* right side grid section */}
          <div className={`${classes["products-grid"]} col-lg-8 col-md-12`}>
            <h2>{header?.toUpperCase()}</h2>
            {loading && <Spinner />}
            {!loading && (
              <>
                {products.length === 0 && !loading && (
                  <div className={classes["empty"]}>
                    <img src={images.emptyBox} alt="" />
                    <h1>No Products Yet</h1>
                  </div>
                )}
                <div className={`${classes["products-list"]} row`}>
                  {typeToShow === "products" &&
                    products.map((product) => {
                      return (
                        <div
                          key={product.id}
                          className="col-xl-4 col-lg-6 col-md-6"
                        >
                          <ProductCard
                            isFav={product.in_wishlist}
                            Key={product.id}
                            product={product}
                          />
                        </div>
                      );
                    })}
                  {typeToShow === "lth" &&
                    lthProducts.map((product) => {
                      return (
                        <div
                          key={product.id}
                          className="col-xl-4 col-lg-6 col-md-6"
                        >
                          <ProductCard
                            isFav={product.in_wishlist}
                            Key={product.id}
                            product={product}
                          />
                        </div>
                      );
                    })}
                  {typeToShow === "htl" &&
                    htlPorducts.map((product) => {
                      return (
                        <div
                          key={product.id}
                          className="col-xl-4 col-lg-6 col-md-6"
                        >
                          <ProductCard
                            isFav={product.in_wishlist}
                            Key={product.id}
                            product={product}
                          />
                        </div>
                      );
                    })}
                </div>
                <ReactPaginate
                  breakLabel="..."
                  pageRangeDisplayed={5}
                  initialPage={currentPage}
                  pageCount={pagesLinks.length}
                  renderOnZeroPageCount={null}
                  className={classes["paginationContainer"]}
                  nextLabel="Next"
                  previousLabel="Prev"
                  activeClassName={classes["activePage"]}
                  onPageChange={handlePageClick}
                  disableInitialCallback={true}
                />
              </>
            )}
          </div>
        </div>
        {/* filter menu button on smaller screens */}
        <Button
          icon="pi pi-filter"
          className={`${classes["filter__menu-button"]} btn-primary`}
          onClick={() => {
            setToggleFilterMenu(!toggleFilterMenu);
          }}
        />
        {/* filter menu on smaller screens */}
        {toggleFilterMenu && (
          <div className={classes["base__filter-menu"]}>
            <RefineMenu
              setOriginal={() => setTypeToShow("products")}
              sortLowToHigh={sortPriceLTH}
              sortHighToLow={sortPriceHTL}
            />
            <FilterMenu type={type} passFilters={getFilterCriteria} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
