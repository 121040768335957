import classes from "./OrderItem.module.scss";
import { useState, useEffect } from "react";

const OrderItem = ({ productData }) => {
  // console.log(productData);
  const [productType, setproductType] = useState("");
  const [gotColor, setGotColor] = useState(false);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    const variationId = productData.pivot.variation_option_id;
    setproductType(productData.product_type);

    if (variationId) {
      const item = productData.variation_options.find(
        (item) => item.id === variationId
      );

      item.options.map((option) => {
        if (option.name === "Size") {
          setSize(option.value);
        }
        if (option.name === "Color") {
          setColor(option.value);
          setGotColor(true);
        }
      });
    }
  }, []);

  if (productData.pivot) {
  }
  // console.log(productData);
  return (
    <div className={classes["orderItem-content"]}>
      <div className={classes["item__img"]}>
        <img src={productData.image.original} alt="cart-item" />
      </div>
      <div className={classes["right-side"]}>
        <p className={classes["product-name"]}>{productData.name}</p>
        <div className={classes["data"]}>
          <div className={classes["rightSideData"]}>
            {productType === "variable" && (
              <>
                <p>
                  Size <span>{size}</span>
                </p>
                {gotColor && (
                  <p>
                    Color <span>{color}</span>
                  </p>
                )}
              </>
            )}
          </div>
          <div className={classes["leftSideData"]}>
            <p>{productData.min_price} EGP</p>
            <p>
              Qty <span>{productData.pivot.order_quantity}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
