import { FaExclamationCircle } from 'react-icons/fa';

function NotFound() {
  return (
    <div style={{ textAlign: 'center' }}>
      <FaExclamationCircle style={{ fontSize: '4rem' }} />
      <h1>404: Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFound;