import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  total: 0,
  msg: "",
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const product = action.payload;
      const items = state.items;
      let existProduct;
      if (product.type === "simple") {
        existProduct = items.find((item) => item.id === product.id);
      } else if (product.type === "variable") {
        existProduct = items.find(
          (item) => item.variantId === product.variantId
        );
      }

      if (existProduct) {
        if (product.type === "simple") {
          if (
            existProduct.quantity + existProduct.productCount <=
            existProduct.maxQuantity
          ) {
            existProduct.quantity += existProduct.productCount;
            state.msg =
              "Added To Cart, Product was added successfully to your cart";
          } else {
            state.msg =
              "Max Quantity Reached, You reached max quantity to be ordered for this product,warn";
          }
        } else if (product.type === "variable") {
          if (existProduct.variantId === product.variantId) {
            if (
              existProduct.quantity + product.productCount <=
              existProduct.maxQuantity
            ) {
              existProduct.quantity += product.productCount;
              state.msg =
                "Added To Cart, Product was added successfully to your cart";
            } else {
              state.msg =
                "Max Quantity Reached, You reached max quantity to be ordered for this product,warn";
            }
          } else {
            items.push({
              type: "variable",
              id: product.id,
              quantity: product.productCount,
              price: product.simplePrice,
              variantId: product.variantId,
              product: product,
              maxQuantity: product.variantOption.quantity,
            });
            state.msg =
              "Added To Cart, Product was added successfully to your cart";
          }
        } else {
          items.push({
            type: "variable",
            id: product.id,
            quantity: product.productCount,
            price: product.price,
            simplePrice: product.simplePrice,
            simpleSalePrice: product.simpleSalePrice,
            variantId: product.variantId,
            product: product,
            maxQuantity: product.variantOption.quantity,
          });
        }
      } else {
        if (product.type === "simple") {
          items.push({
            type: "simple",
            id: product.id,
            quantity: product.productCount,
            product: product,
            maxQuantity: product.quantity,
            slug: product.slug,
          });
        } else if (product.type === "variable") {
          items.push({
            type: "variable",
            id: product.id,
            quantity: product.productCount,
            product: product,
            maxQuantity: product.variantOption.quantity,
            slug: product.slug,
            price: product.variablePrice,
            salePrice: product.variableSalePrice,
            variantId: product.variantId,
            size: product.selectedSize.name,
          });
        }
        state.msg =
          "Added To Cart, Product was added successfully to your cart";
      }
      state.items = [...items];
    },
    removeFromCart: (state, action) => {
      const { id, variantId, type } = action.payload;
      if (type === "simple") {
        state.items = state.items.filter((item) => item.id !== id);
        return;
      }
      if (type === "variable") {
        state.items = state.items.filter(
          (item) => item.variantId !== variantId
        );
      }
    },
    handleIncrease: (state, action) => {
      const { id, type, variantId, maxQuantity } = action.payload;
      if (type === "simple") {
        const product = state.items.find((item) => item.id === id);
        if(product.quantity + 1 <= maxQuantity) product.quantity += 1
        return;
      }
      if (type === "variable") {
        const product = state.items.find(
          (item) => item.variantId === variantId
        );
        if(product.quantity + 1 <= maxQuantity) product.quantity += 1
      }
    },
    handleDecrease: (state, action) => {
        const { id, type, variantId } = action.payload;
        if (type === "simple") {
          const product = state.items.find((item) => item.id !== id);
          if(product.quantity - 1 === 0) product.quantity -= 1
          return;
        }
        if (type === "variable") {
          const product = state.items.find(
            (item) => item.variantId === variantId
          );
          if(product.quantity - 1 === 1) product.quantity -= 1
        }
    },
    resetMessage: (state) => {
      state.msg = '';
    }
  },
});
export const { addToCart, removeFromCart, handleDecrease, handleIncrease, resetMessage } = cartSlice.actions;
export default cartSlice;
