import classes from "./OrderListItem.module.scss";
import { Link } from "react-router-dom";

const OrderListItem = (props) => {
  return (
    <Link to={`details/${props.number}`}>
      <div className={classes["orders__list-item"]}>
        <div className={classes["item__img"]}>
          <img src={props.image} alt="cart-item" />
        </div>
        <div className={classes["order-rightSide"]}>
          <div className={classes["order-details"]}>
            <div>
              <p>Order Date</p>
              <p>{props.date}</p>
            </div>
            <div>
              <p>Order Number</p>
              <p>{props.number}</p>
            </div>
            <div>
              <p>Order Status</p>
              <p>{props.status}</p>
            </div>
          </div>
          <i className="pi pi-chevron-right"></i>
        </div>
      </div>
    </Link>
  );
};

export default OrderListItem;
