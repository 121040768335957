import feature1 from "../assets/images/home/feature1.jpg";
import feature2 from "../assets/images/home/feature2.jpg";
import feature3 from "../assets/images/home/feature3.jpg";
import feature4 from "../assets/images/home/feature4.jpg";
import zSolutions from "../assets/images/zSolutions2.webp";
import emptyBox from "../assets/images/emptyBox.svg";

export default {
  feature1,
  feature2,
  feature3,
  feature4,
  zSolutions,
  emptyBox,
};
