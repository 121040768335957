/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Galleria } from "primereact/galleria";
import { Toast } from "primereact/toast";
import { FiHeart } from "react-icons/fi";
import { Button, CardSlider, DropdownInput } from "../../components";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

import classes from "./ProductView.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, resetMessage } from "../../redux/slices/cart.slice";
import { fetchUserById } from "../../redux/slices/product.slice";
import ImageGallery from "../../components/ImageGallary";
import Spinner from "../../components/Spinner";
import NotFound from "../../components/NotFound";
import env from "react-dotenv";

const ProductView = ({ showDialog }) => {
  const token = localStorage.getItem("userToken");
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { msg } = useSelector((state) => state.cart);
  const { fetchedProduct, loading, error } = useSelector((state) => state.product);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(windowWidth < 575);
  const [isGallery, setIsGallery] = useState(true);

  const [product, setProduct] = useState({});
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [variablePrice, setVariablePrice] = useState(null);
  const [variableSalePrice, setVariableSalePrice] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedVariantQuantity, setSelectedVariantQuantity] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [outOfStock, setOutOfStock] = useState(false);
  const [getVariantOption, setGetVariantOption] = useState(null);
  const [thumbnailActive, setThumbnailActive] = useState(true);
  const location = useLocation();
  const { productID: slug } = useParams();
  const ProductSlug = location.state?.slug || slug;
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleFetchProduct = (data) => {
    const fetchedProduct = data;
    const {
      id,
      name,
      description,
      price: simplePrice,
      product_type: type,
      image: { original: image },
      shop: { name: brand, description: brandDescription },
      in_wishlist: isFav,
      min_price: minPrice,
      max_price: maxPrice,
      sale_price: simpleSalePrice,
      gallery,
      quantity,
      meta_data: { related_products: similarProducts },
      variations,
      variation_options,
      material,
      size_chart: sizeChart,
    } = fetchedProduct;

    const mappedGallery = gallery.map((galleryItem) => ({
      itemImageSrc: galleryItem.original,
      thumbnailImageSrc: galleryItem.original,
      alt: "Description for Image",
      title: galleryItem.id,
    }));
    const sizes = variations
      .filter((item) => item.attribute.name === "Size")
      .map((item) => ({
        name: item.value,
        code: item.slug,
      }));

    const colors = variations
      .filter((item) => item.attribute.name === "Color")
      .map((item) => ({
        name: item.value,
        code: item.slug,
      }));

    const mappedVariationOptions = variation_options.map((option) => ({
      id: option.id,
      title: option.title,
      price: option.price,
      quantity: option.quantity,
      salePrice: option.sale_price,
    }));

    const ProductData = {
      id,
      name,
      description,
      simplePrice,
      type,
      image,
      brand,
      brandDescription,
      isFav,
      minPrice,
      maxPrice,
      simpleSalePrice,
      quantity,
      gallery: mappedGallery,
      similarProducts,
      sizes,
      colors,
      variationOptions: mappedVariationOptions,
      material,
      sizeChart,
    };
    const sale = maxPrice - minPrice;
    if (sale) {
      if (getVariantOption) {
        const { price, salePrice } = getVariantOption;
        setVariablePrice(price);
        setVariableSalePrice(salePrice);
      } else {
        setVariablePrice(maxPrice);
        setVariableSalePrice(sale);
      }
    } else {
      setVariableSalePrice(null);
      setVariablePrice(ProductData.minPrice);
    }

    setIsGallery(ProductData.gallery.length > 0);
    setProduct(ProductData);
    setSizes(ProductData.sizes);
    setColors(ProductData.colors);
    if (ProductData.type === "simple") {
      if (ProductData.quantity >= 1) {
        setSelectedVariantQuantity(ProductData.quantity);
        setProductCount(1);
      } else {
        setOutOfStock(true);
      }
    }
  };

  useEffect(() => {
    handleWindowResize();
    window.scrollTo(0, 0);
  }, [ProductSlug]);

  useEffect(() => {
    dispatch(fetchUserById(ProductSlug));
    setProductCount(0);
    setSelectedSize(null);
    setSelectedColor(null);
    setSelectedVariantQuantity(null);
    setVariablePrice(null);
    setGetVariantOption(null);
  }, [location]);

  useEffect(() => {
    handleFetchProduct(fetchedProduct);
  }, [fetchedProduct]);

  useEffect(() => {
    if (msg) {
      const messages = msg.split(",");
      show(...messages);
    }
    dispatch(resetMessage());
  }, [msg]);

  useEffect(() => {
    setIsGallery(true);

    window.scrollTo(0, 0);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowWidth]);

  useEffect(() => {
    handleChange();
  }, [selectedColor, selectedSize]);

  const handleChange = () => {
    if (product.type === "variable") {
      if (sizes && colors.length === 0) {
        if (selectedSize) {
          const combined = selectedSize.name;
          for (const option of product.variationOptions) {
            if (option.title === combined) {
              setGetVariantOption(option);
              if (option.salePrice) {
                setVariablePrice(option.price);
                setVariableSalePrice(option.salePrice);
              } else {
                setVariablePrice(option.price);
              }
              setSelectedVariant(option.id);
              if (option.quantity > 0) {
                setSelectedVariantQuantity(option.quantity);
                setProductCount(1);
                setOutOfStock(false);
              } else {
                setSelectedVariantQuantity(0);
                setProductCount(0);
                setOutOfStock(true);
              }
            }
          }
        }
      } else if (sizes && colors) {
        if (selectedSize && selectedColor) {
          const combined = selectedSize.name + "/" + selectedColor.name;
          for (const option of product.variationOptions) {
            if (option.title === combined) {
              setGetVariantOption(option);
              setSelectedVariant(option.id);
              if (option.salePrice) {
                setVariableSalePrice(option.salePrice);
                setVariablePrice(option.price);
              } else {
                setVariablePrice(option.price);
              }
              if (option.quantity > 0) {
                setSelectedVariantQuantity(option.quantity);
                setProductCount(1);
                setOutOfStock(false);
              } else {
                setSelectedVariantQuantity(0);
                setProductCount(0);
                setOutOfStock(true);
              }
            }
          }
        }
      }
    }
  };
  const handleWindowResize = () => {
    const newWidth = window.innerWidth;
    setWindowWidth(newWidth);
    setIsMobile(newWidth < 768);
    if (newWidth < 768) {
      setThumbnailActive(false);
    } else {
      setThumbnailActive(true);
    }
  };

  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 3,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 3,
    },
  ];

  const openFullScreenImage = (event) => {
    const image = event.target;
    const src = image.src;
    const alt = image.alt;

    const overlay = document.createElement("div");
    overlay.classList.add("overlay");

    const img = document.createElement("img");
    img.src = src;
    img.alt = alt;
    img.classList.add("full-screen-image");

    overlay.appendChild(img);
    document.body.appendChild(overlay);

    overlay.addEventListener("click", function () {
      overlay.remove();
    });
  };

  const itemTemplate = (item) => {
    return (
      <img
        src={item.itemImageSrc}
        alt={item.alt}
        onClick={(e) => openFullScreenImage(e)}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return <img src={item.thumbnailImageSrc} alt={item.alt} />;
  };

  const handleAddToCart = () => {
    if (token === null) {
      showDialog();
      return;
    }
    const selectedProduct = {
      ...product,
      variantOption: getVariantOption,
      variantId: selectedVariant,
      slug: ProductSlug,
      productCount,
      selectedSize,
      variablePrice,
      variableSalePrice,
    };
    dispatch(addToCart(selectedProduct));
    // window.dispatchEvent(new Event("storage"));
  };
  const addToFav = useCallback((id) => {
    axios.post(
      `${BASE_URL}/wishlists`,
      {
        product_id: id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }, []);

  const deleteFromFav = useCallback((id) => {
    axios.delete(`${BASE_URL}/wishlists/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }, []);

  const addToFavHandler = () => {
    const id = product.id;
    if (!product.isFav) {
      addToFav(id);
      setProduct({ ...product, isFav: true });
    } else if (product.isFav) {
      deleteFromFav(id);
      setProduct({ ...product, isFav: false });
    }
  };

  const sizeChangeHandler = (e) => {
    setSelectedSize(e.value);
  };

  const colorChangeHandler = (e) => {
    setSelectedColor(e.value);
  };

  const show = (summary, msg, variant = "info") => {
    toast.current.show({
      severity: variant,
      summary: summary,
      detail: msg,
    });
  };

  const handleDecrease = () => {
    if (selectedVariantQuantity !== 0) {
      if (productCount > 1) {
        setProductCount((prevCount) => prevCount - 1);
      }
    }
  };

  const handleIncrease = () => {
    if (selectedVariantQuantity !== 0) {
      if (productCount < selectedVariantQuantity) {
        setProductCount((prevCount) => prevCount + 1);
      }
    }
  };
  if (error || !product) {
    return (
      <div className={classes["base__productView"]}>
        <NotFound />
      </div>
    );
  }
  return (
    <div
      className={`${classes["base__productView"]} ${
        loading ? "d-flex justify-content-center align-items-center" : null
      }`}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="container">
            <div className={classes["base__productView-content"]}>
              <div className="row">
                {/* product img gallery */}
                <div
                  className={`${classes["product__gallery"]} col-xl-6 col-lg-6`}
                >
                  {isGallery ? (
                    <>
                      {product.gallery && isMobile && (
                        <ImageGallery images={product.gallery} />
                      )}
                      {!isMobile && (
                        <Galleria
                          value={product.gallery}
                          var="photo"
                          responsiveOptions={responsiveOptions}
                          showThumbnails={thumbnailActive}
                          showIndicators={!thumbnailActive}
                          indicatorsPosition="left"
                          numVisible={3}
                          circular="true"
                          autoPlay={true}
                          transitionInterval="2000"
                          item={itemTemplate}
                          thumbnail={thumbnailTemplate}
                          thumbnailsPosition="left"
                        />
                      )}
                    </>
                  ) : (
                    <img src={product.image} alt="product img" />
                  )}
                </div>
                {/* product general info - right side */}
                <div
                  className={`${classes["product__generalInfo"]} col-xl-6 col-lg-6 col-md-12`}
                >
                  <div className={classes["product__id"]}>
                    <h3 className={classes["product__id-name"]}>
                      {product.name}
                    </h3>
                    <p className={classes["product__id-brand"]}>
                      {product.brand}
                    </p>
                    <p className={classes["product__id-price"]}>
                      {product.type === "simple" &&
                        !product.simpleSalePrice &&
                        `${product.simplePrice} EGP`}
                      {product.type === "simple" && product.simpleSalePrice && (
                        <>
                          <span className={classes["originalPrice"]}>
                            {product.simplePrice} EGP
                          </span>
                          <span className={classes["salePrice"]}>
                            {product.simpleSalePrice} EGP
                          </span>
                        </>
                      )}
                      {product.type === "variable" &&
                        !variableSalePrice &&
                        variablePrice &&
                        `${variablePrice} EGP`}
                      {product.type === "variable" && variableSalePrice && (
                        <>
                          <span className={classes["originalPrice"]}>
                            {variablePrice} EGP
                          </span>
                          <span className={classes["salePrice"]}>
                            {variableSalePrice} EGP
                          </span>
                        </>
                      )}
                    </p>
                  </div>

                  {sizes.length > 0 && (
                    <div className={classes["product__sizes"]}>
                      <DropdownInput
                        options={sizes}
                        value={selectedSize}
                        onChange={(e) => {
                          sizeChangeHandler(e);
                        }}
                        id="size"
                        placeholder="Select a Size"
                      />
                    </div>
                  )}
                  {colors.length > 0 && (
                    <div className={classes["product__colors"]}>
                      <DropdownInput
                        options={colors}
                        value={selectedColor}
                        onChange={(e) => {
                          colorChangeHandler(e);
                        }}
                        id="color"
                        placeholder="Select a Color"
                      />
                    </div>
                  )}

                  <div className={classes["item-counter"]}>
                    <i className="pi pi-minus" onClick={handleDecrease}></i>
                    <p>{productCount}</p>
                    <i className="pi pi-plus" onClick={handleIncrease}></i>
                  </div>
                  {outOfStock && (
                    <p className={classes["stockHint"]}>Out of Stock</p>
                  )}
                  {selectedVariantQuantity === 1 && (
                    <p className={classes["stockHint"]}>1 item left</p>
                  )}

                  <div className={classes["product__action-icons"]}>
                    {token && (
                      <FiHeart
                        className={`${classes["favIcon"]} ${
                          product.isFav ? classes["active"] : ""
                        }`}
                        onClick={addToFavHandler}
                      />
                    )}
                    {/* <FiShare /> */}
                  </div>

                  <Button
                    onClick={handleAddToCart}
                    disabled={
                      (product.type === "variable" && !selectedVariant) ||
                      outOfStock
                    }
                    className="btn-secondary w-100"
                    label="Add To Cart"
                  />
                </div>
              </div>
              {/* product details - brand, size shart, material.... */}
              <div className={`${classes["product__details"]} row`}>
                <div className="col-lg-6 col-md-12">
                  <h4>Details</h4>
                  <p>{product.description}</p>
                </div>
                <div className="col-lg-6 col-md-12">
                  <h4>About The Brand</h4>
                  <p>{product.brandDescription}</p>
                </div>
                <div className="col-lg-6 col-md-12">
                  <h4>Material</h4>
                  <p>{product.material}</p>
                </div>
                <div className="col-lg-6 col-md-12">
                  <h4>Size Chart</h4>
                </div>
              </div>
              <div className={classes["slider__container"]}>
                <CardSlider
                  sliderRows={1}
                  productsList={product.similarProducts}
                  className={classes["custom__img-width"]}
                />
              </div>
            </div>
          </div>
          <Toast ref={toast} />
        </>
      )}
    </div>
  );
};

export default ProductView;
