import { Calendar } from "primereact/calendar";
import classes from "./CalendarInput.module.scss";

const CalendarInput = (props) => {
  return (
    <span className={classes["calendarField"]}>
      <label htmlFor={props.id}>{props.label}</label>
      <Calendar inputId={props.id} placeholder={props.placeholder} />
    </span>
  );
};

export default CalendarInput;
