import classes from "./Wallet.module.scss";

const Wallet = () => {
  return (
    <div className={classes["wallet__content"]}>
      <h1>wallet</h1>

      <div className={classes["balance"]}>
        <div className={classes["balance-amount"]}>
          <h4>Current Balance</h4>
          <p>130 EGP</p>
        </div>
        <div className={classes["balance-add"]}>
          <p>Add Credit</p>
          <i className="pi pi-chevron-right"></i>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
