import classes from "./AddressListItem.module.scss";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const AddressListItem = ({
  addressInfo,
  selectedAddress,
  changeSelectedAddressHandler,
}) => {
  return (
    <div
      onClick={() =>
        changeSelectedAddressHandler(addressInfo.addressId, addressInfo)
      }
      className={classes["address__listItem"]}
    >
      <h5>{addressInfo.primary ? "Primary" : ""} Address</h5>
      <div className={classes["address__listItem-content"]}>
        <div className={classes["addressInfo"]}>
          <p>
            {addressInfo.street1}, {addressInfo.street2}
          </p>
          <p>
            {addressInfo.city}, {addressInfo.country}
          </p>
          <p>
            {addressInfo.landmark}, {addressInfo.phone}
          </p>
        </div>
        <div className={classes["actions"]}>
          <BsFillCheckCircleFill
            className={
              addressInfo.addressId === selectedAddress.addressId
                ? classes["selected"]
                : ""
            }
          />
          <p className={classes["editLink"]}>
            <Link
              to={"add-new-address"}
              state={{ formType: "edit", address: addressInfo }}
            >
              Edit Address <FiChevronRight />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddressListItem;
