import { Accordion, AccordionTab } from "primereact/accordion";
import { Slider } from "primereact/slider";
import { Checkbox } from "primereact/checkbox";
import axios from "axios";

import { useCallback, useState, useEffect } from "react";
import { Button } from "../../components";

import classes from "./FilterMenu.module.scss";

const FilterMenu = (props) => {
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchBrands = useCallback(() => {
    axios.get(`${BASE_URL}/shops`).then((Response) => {
      const fetchedBrands = Response.data.data.map((brand) => {
        return {
          id: brand.id,
          name: brand.name,
          slug: brand.slug,
        };
      });
      setBrands([...fetchedBrands]);
    });
  }, []);

  const fetchCategories = useCallback((_type) => {
    axios
      .get(`${BASE_URL}/categories?search=type.slug:${_type}`)
      .then((Response) => {
        const fetchedCategories = Response.data.data.map((category) => {
          return {
            id: category.id,
            name: category.name,
            slug: category.slug,
          };
        });
        setCategories([...fetchedCategories]);
      });
  }, []);

  const sizes = [
    { name: "3XS", key: "S1" },
    { name: "2XS", key: "S2" },
    { name: "XS", key: "S3" },
    { name: "S", key: "S4" },
    { name: "M", key: "S5" },
    { name: "L", key: "S6" },
    { name: "XL", key: "S7" },
    { name: "2XL", key: "S8" },
    { name: "3XL", key: "S9" },
  ];

  useEffect(() => {
    fetchBrands();
    fetchCategories(props.type);
  }, [props.type]);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const onCategoryChange = (e) => {
    let _selectedCategories = [...selectedCategories];

    if (e.checked) _selectedCategories.push(e.value);
    else
      _selectedCategories = _selectedCategories.filter(
        (category) => category.id !== e.value.id
      );

    setSelectedCategories(_selectedCategories);
  };

  const onBrandChange = (e) => {
    let _selectedBrands = [...selectedBrands];

    if (e.checked) _selectedBrands.push(e.value);
    else
      _selectedBrands = _selectedBrands.filter(
        (brand) => brand.id !== e.value.id
      );

    setSelectedBrands(_selectedBrands);
  };

  const applyFilter = () => {
    const brandsId = selectedBrands.map((brand) => brand.id);
    const categoriesId = selectedCategories.map((cat) => cat.slug);

    props.passFilters(brandsId, categoriesId);
  };

  return (
    <div className={props.className}>
      <h4 className={classes["filterMenu__header"]}>Filters</h4>
      {/* filters accordion */}
      <Accordion>
        <AccordionTab header="Category">
          {categories.map((category) => {
            return (
              <div key={category.id} className="checkbox-holder">
                <Checkbox
                  inputId={category.id}
                  name="category"
                  value={category}
                  onChange={onCategoryChange}
                  checked={selectedCategories.some(
                    (item) => item.id === category.id
                  )}
                />
                <label htmlFor={category.id}>{category.name}</label>
              </div>
            );
          })}
        </AccordionTab>
        <AccordionTab header="Brand">
          {brands.map((brand) => {
            return (
              <div key={brand.id} className="checkbox-holder">
                <Checkbox
                  inputId={brand.id}
                  name="brand"
                  value={brand}
                  onChange={onBrandChange}
                  checked={selectedBrands.some((item) => item.id === brand.id)}
                />
                <label htmlFor={brand.id}>{brand.name}</label>
              </div>
            );
          })}
        </AccordionTab>
        {/* <AccordionTab header="Colors"></AccordionTab>
        <AccordionTab header="Size">
          {sizes.map((size) => {
            return (
              <div key={size.key} className="checkbox-holder">
                <Checkbox
                  inputId={size.key}
                  name="size"
                  value={size}
                  onChange={onSizeChange}
                  checked={selectedSizes.some((item) => item.key === size.key)}
                />
                <label htmlFor={size.key}>{size.name}</label>
              </div>
            );
          })}
        </AccordionTab> */}
        {/* <AccordionTab className={"priceFilter"} header="Price">
          <p>
            <span>{priceFilterValue[0]}</span>
            <span>{priceFilterValue[1]}</span>
          </p>
          <Slider
            range
            value={priceFilterValue}
            onChange={(e) => setpriceFilterValue(e.value)}
          />
        </AccordionTab> */}
      </Accordion>
      <Button
        onClick={applyFilter}
        className={`${classes["apply-filter"]} btn-primary`}
        label="Apply"
      />
    </div>
  );
};

export default FilterMenu;
