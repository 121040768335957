import { Button } from "primereact/button";
import classes from "./Button.module.scss";

const SharedButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      label={props.label}
      className={props.className}
      icon={props.icon}
      type={props.type}
      disabled={props.disabled}
    />
  );
};

export default SharedButton;
