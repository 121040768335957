import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUserById = createAsyncThunk(
  "products/getProduct",
  async (ProductSlug) => {
    const token = localStorage.getItem("userToken");
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const response = await axios.get(
      `${BASE_URL}/products/${ProductSlug}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  }
);

const initialState = {
  fetchedProduct: undefined,
  loading: false,
  error: '',
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
     builder.addCase(fetchUserById.fulfilled ,(state, action) => {
        state.fetchedProduct = action.payload;
        state.loading = false;
        state.error = '';
     }).addCase(fetchUserById.pending, (state) => {
        state.loading = true;
        state.error = '';
     }).addCase(fetchUserById.rejected, (state, action) => {
        state.error = action.payload;
        state.fetchedProduct = undefined;
        state.loading = false;
     })
  }
});
export default productSlice;
