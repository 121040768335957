import { Outlet } from "react-router-dom";
import classes from "./OrdersAndReturns.module.scss";
import { useEffect } from "react";

const OrdersAndReturns = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Outlet />;
};

export default OrdersAndReturns;
