import classes from "./OrdersList.module.scss";
import OrderListItem from "./OrderListItem/OrderListItem";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const OrdersList = () => {
  const token = localStorage.getItem("userToken");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [orders, setOrders] = useState([]);

  const fetchOrders = useCallback(() => {
    axios
      .get(`${BASE_URL}/orders`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedOrders = response.data.data;
        console.log(fetchedOrders);
        const ordersData = fetchedOrders.map((order) => {
          return {
            orderId: order.id,
            orderDate: order.created_at.slice(0, 10),
            orderNumber: order.tracking_number,
            orderStatus: order.order_status,
            orderImage: order.products[0]?.image.original,
          };
        });
        setOrders([...ordersData]);
      });
  }, []);

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className={classes["ordersAndReturns__content"]}>
      <h1>Orders and Returns</h1>
      <div className={classes["order-tabs"]}>
        <h2
          // onClick={() => selectOrderType("Order")}
          className={orders ? classes["active"] : ""}
        >
          Orders
        </h2>
        {/* <h2
          onClick={() => selectOrderType("Return")}
          className={Returns ? classes["active"] : ""}
        >
          Returns
        </h2> */}
      </div>
      <div className={classes["order-list"]}>
        {orders.map((order) => {
          return (
            <OrderListItem
              date={order.orderDate}
              number={order.orderNumber}
              status={order.orderStatus}
              image={order.orderImage}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OrdersList;
