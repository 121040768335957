import classes from "./ContactUs.module.scss";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes["contact-container"]}>
      <h1>Contact Us</h1>

      <h3>
        You can contact us at <a href="mailto:contact@base-egypt.com">BASE</a>.
      </h3>

      <div className={classes["socials"]}>
        <h3>Reachout </h3>
        <div className={classes["socialItem"]}>
          <BsInstagram />
          <p>
            <a href="https://www.instagram.com/baseegypt/">
              instagram.com/baseegypt
            </a>{" "}
          </p>
        </div>
        <div className={classes["socialItem"]}>
          <FaFacebookF />
          <p>
            <a href="https://www.facebook.com/baseegyptshop">
              facebook.com/baseegyptshop
            </a>
          </p>
        </div>
        <div className={classes["socialItem"]}>
          <FaTiktok />
          <p>
            <a href="https://www.tiktok.com/@baseegypt">
              tiktok.com/@baseegypt
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
