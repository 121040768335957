import classes from "./SettingsMainPage.module.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const SettingsList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const handleLogout = () => {
    axios.post(`${BASE_URL}/logout`);
    localStorage.clear();
    localStorage.setItem("totalQuantity", 0);
    window.dispatchEvent(new Event("storage"));
    navigate("/");
  };

  return (
    <div className={classes["settingsList"]}>
      <div className={`${classes["account__header"]}`}>
        <h2>Welcome To Your Account</h2>
      </div>

      <div className={classes["account__options"]}>
        <h4>My Account</h4>
        <div className={classes["account__options-list"]}>
          <Link to={"settings-list/orders-and-returns"}>
            <p>
              Orders & Returns <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          {/* <Link to={"settings-list/wallet"}>
            <p>
              Wallet <i className="pi pi-angle-right"></i>
            </p>
          </Link> */}
          {/* <Link to={"settings-list/details-and-password"}>
            <p>
              Details & Password <i className="pi pi-angle-right"></i>
            </p>
          </Link> */}
          <Link to={"settings-list/address-book"}>
            <p>
              Address Book <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          {/* <Link to={"settings-list/refer-a-friend"}>
            <p>
              Refer a Friend <i className="pi pi-angle-right"></i>
            </p>
          </Link> */}
          <Link to={"settings-list/wishlist"}>
            <p>
              Wishlist <i className="pi pi-angle-right"></i>
            </p>
          </Link>
        </div>
        <h4>Support</h4>
        <div className={classes["account__options-list"]}>
          <Link to={"settings-list/about"}>
            <p>
              About Us
              <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          <Link to={"settings-list/terms-and-conditions"}>
            <p>
              Terms & Conditions <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          <Link to={"settings-list/privacy-policy"}>
            <p>
              Privacy Policy <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          <Link to={"settings-list/faqs-and-guides"}>
            <p>
              Refund and Exchange policy <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          <Link to={"settings-list/contact-us"}>
            <p>
              Contact Us <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          <Link
            target="_blank"
            to="https://docs.google.com/forms/d/1qiPkmMTsvtOVBLhQY17P2CgiUxrLs1P4t5UyGWwZJE4/viewform?ts=646a53f8&edit_requested=true"
          >
            <p>
              Join as a Seller <i className="pi pi-angle-right"></i>
            </p>
          </Link>
          <p onClick={handleLogout}>
            Sign Out <i className="pi pi-angle-right"></i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SettingsList;
