import ProductCard from "../ProductCard/ProductCard";
import classes from "./CardSlider.module.scss";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const CardSlider = ({ className = "", sliderRows = 1, productsList = [] }) => {
  const createProductLists = () => {
    const sliderProducts = productsList.map((product) => {
      return (
        <ProductCard
          isFav={product.in_wishlist}
          key={product.id}
          product={product}
          className={className}
        />
      );
    });

    if (sliderRows === 1) {
      return [<div className={classes["product-list"]}>{sliderProducts}</div>];
    }

    const midIndex = Math.ceil(sliderProducts.length / 2);
    const firstHalf = sliderProducts.slice(0, midIndex);
    const secondHalf = sliderProducts.slice(midIndex);

    return [
      <div className={classes["product-list"]}>{firstHalf}</div>,
      <div className={classes["product-list"]}>{secondHalf}</div>,
    ];
  };

  const sliderLists = createProductLists();

  return (
    <div className={classes["base__slider"]}>
      <div className={classes["slider"]}>{sliderLists}</div>
    </div>
  );
};

export default CardSlider;
