import { useLayoutEffect, useRef, useState } from 'react';

const ImageGallery = (props) => {
  const galleryRef = useRef(null);
  const [images] = useState(props.images || []);

  useLayoutEffect(() => {
    const galleryPanel = galleryRef.current;
    const isMobileScreen = window.innerWidth < 767;
    if (galleryPanel && isMobileScreen) {
      galleryPanel.style.overflowX = 'scroll';
      galleryPanel.style.whiteSpace = 'nowrap';
    } else if (galleryPanel) {
      galleryPanel.style.overflowX = 'hidden';
      galleryPanel.style.whiteSpace = 'normal';
    }
  }, [galleryRef]);

  return (
    <div className="gallery" ref={galleryRef}>
      {images.map((image, index) => (
        <img className="image" key={index} src={image.itemImageSrc} alt={image.title} />
      ))}
    </div>
  );
};

export default ImageGallery;