import { CardSlider } from "../../../../components";
import classes from "./CategoryView.module.scss";

const CategoryView = (props) => {
  return (
    <div className={classes["base__home-categoryView"]}>
      <h4>{props.header}</h4>
      <p>{props.description}</p>
      <CardSlider
        sliderRows={props.sliderRows}
        productsList={props.products}
        className={props.className}
      />
    </div>
  );
};

export default CategoryView;
