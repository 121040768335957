import { InputText } from "primereact/inputtext";
import classes from "./TextInput.module.scss";

const TextInput = (props) => {
  return (
    <div className={classes["inputField"]}>
      <label htmlFor={props.id}>
        {props.label}
        {!props.required && <span>*</span>}
      </label>
      <InputText
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        id={props.id}
        required={props.required === "no" ? false : true}
        disabled={props.disabled || false}
        aria-describedby="inputtext-help"
      />
      <small id="inputtext-help">{props.hint}</small>
    </div>
  );
};

export default TextInput;
